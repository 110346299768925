import { OfferCampaignApiDto } from '@b2x/storefront-api-js-client/src';

import { Div } from './HTMLElement';

export interface CartCampaignMultiOffersProps {
  campaign: OfferCampaignApiDto;
}

/*
  Dovrebbero avere una grafica diversa, con un raggruppamento e una progress bar,
  ma per ora li tratto come una lista di elementi singoli.
*/
export const CartCampaignMultiOffers = ({ campaign }: CartCampaignMultiOffersProps) => {
  return <Div className="cart-campaign-multi-offers" />;
};
