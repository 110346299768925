import { ProductApiDto, SkuApiDto } from '@b2x/storefront-api-js-client/src/dto';
import React from 'react';

export const useGallery = (product: ProductApiDto, sku?: SkuApiDto) => {
  const gallery = React.useMemo(() => {
    const slides: Array<{ src: string; thumbnail: string; type: 'image' | 'video'; uId: string }> = [];

    const videoUrl = product.attributes
      ?.find((attribute) => attribute.typeCode.includes('VIDEO_URL'))
      ?.value?.split('|');
    const videoThumbnail = product.attributes
      ?.find((attribute) => attribute.typeCode.includes('VIDEO_IMAGE_URL'))
      ?.value?.split('|');

    if (sku?.image?.src) {
      slides.push({
        src: sku.image.src,
        thumbnail: sku.image.src,
        type: 'image',
        uId: sku.image.id,
      });
    } else if (product.image?.src) {
      slides.push({
        src: product.image.src,
        thumbnail: product.image.src,
        type: 'image',
        uId: product.image.id,
      });
    }

    if (sku?.alternativeImages && sku.alternativeImages.length > 0) {
      sku.alternativeImages.forEach(
        (image) => image.src && slides.push({ src: image.src, thumbnail: image.src, type: 'image', uId: image.id })
      );
    } else if (product.alternativeImages && product.alternativeImages.length > 0) {
      product.alternativeImages.forEach(
        (image) => image.src && slides.push({ src: image.src, thumbnail: image.src, type: 'image', uId: image.id })
      );
    }

    videoUrl?.forEach((video, index) => {
      slides.push({ src: video, thumbnail: videoThumbnail?.at(index) ?? '', type: 'video', uId: video });
    });

    return { slides };
  }, [
    sku?.alternativeImages,
    sku?.image,
    product.alternativeImages,
    product.attributes,
    product.image?.id,
    product.image?.src,
  ]);

  return gallery;
};
