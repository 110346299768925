import { CartApiDto, CartSkuApiDto, CheckoutApiType } from '@b2x/storefront-api-js-client/src/';
import { GetCartOptions } from '@b2x/storefront-api-js-client/src/cart';
import React from 'react';

import { analytics } from './analytics/analytics';
import { ApiRequestConfig } from './api/useApiRequest';
import { useCartApi } from './api/useCartApi';
import { useAppContext } from './AppContext';
import { appConfig } from './config';
import { useStable } from './util';

export interface UseCartOptions extends GetCartOptions {
  cartSkusToMoveCampaignsCodes?: Array<string>;
}

export const useCart = (options?: UseCartOptions, config?: ApiRequestConfig) => {
  const [cart, setCart] = React.useState<CartApiDto>();
  // const [campaignsCartSkus, setCampaignsCartSkus] = React.useState<Partial<Record<string, Array<CartSkuApiDto>>>>({});

  const { getCart } = useCartApi();
  const { session } = useAppContext();

  options = useStable(options);
  config = useStable(config);

  /*
  Prendo il carrello al primo caricamento e ogni volta che cambia quello in sessione, per garantirne la coerenza.
  L'iter sarà quindi:
   - sto nella pagina carrello
   - modifico una quantità
   - alla onSuccess verrà aggiornato il carrello in sessione
   - la modifica del carrello in sessione scatenerà questo aggiornamento del carrello in pagina
  Ricapitolado, qualsiasi modifica al carrello deve prima passare per quello in sessione, che a cascata scanerà l'aggiornamento di questo.
  */
  React.useEffect(() => {
    getCart(options, config).then((response) => {
      setCart(response.data);
    });
  }, [config, getCart, options, session?.cart]);

  const blockingAlerts = React.useMemo(
    () => cart?.alerts?.filter((alert) => appConfig.cartBlockingAlerts?.includes(alert.code as CheckoutApiType)),
    [cart?.alerts]
  );

  const canMoveOn = React.useMemo(() => blockingAlerts && blockingAlerts.length === 0, [blockingAlerts]);

  // Mando evento di "viewCart"
  const analyticsViewCartEventSent = React.useRef<boolean>(false);
  React.useEffect(() => {
    if (cart && !analyticsViewCartEventSent.current) {
      /*
        FIXME Da capire come fare. lato back la getCart verrà fatta a ogni modifica del carrello, ma solo la prima dovrebbe generare l'evento?
        In caso mi salvo il primo eventId?
      */
      analytics.events.viewCart('EVENT_ID', cart);
      analyticsViewCartEventSent.current = true;
    }
  }, [cart]);

  const fixedCart = React.useMemo<CartApiDto | undefined>(
    () =>
      cart
        ? {
            ...cart,
            packs: cart.packs?.map((pack) => ({
              ...pack,
              // Prendo i cartSku che appartengono alle campagne per le quali voglio mostrare i prodotti separatamente, e li levo dai packs.
              cartSkus: pack.cartSkus?.filter((cartSku) => {
                const isCartSkuToRemove = cartSku.offers?.some(
                  (offer) => offer.campaign && options?.cartSkusToMoveCampaignsCodes?.includes(offer.campaign)
                );
                return !isCartSkuToRemove;
              }),
            })),
          }
        : undefined,
    [cart, options?.cartSkusToMoveCampaignsCodes]
  );

  // Prendo i cartSku che appartengono alle campagne per le quali voglio mostrare i prodotti separatamente, e metto da parte.
  const campaignsCartSkus = React.useMemo<Partial<Record<string, Array<CartSkuApiDto>>> | undefined>(
    () =>
      options?.cartSkusToMoveCampaignsCodes?.reduce<Partial<Record<string, Array<CartSkuApiDto>>>>(
        (acc, campaignCode) => {
          const skusToMoveForThisCampaign: Array<CartSkuApiDto> = [];
          cart?.packs?.forEach((pack) => {
            pack.cartSkus?.forEach((cartSku) => {
              const isCartSkuToMoveForThisCampaign = cartSku.offers?.some(
                (offer) => offer.campaign && offer.campaign === campaignCode
              );
              if (isCartSkuToMoveForThisCampaign) {
                skusToMoveForThisCampaign.push(cartSku);
              }
            });
          });
          acc[campaignCode] = skusToMoveForThisCampaign;
          return acc;
        },
        {}
      ),
    [cart?.packs, options?.cartSkusToMoveCampaignsCodes]
  );

  return { blockingAlerts, campaignsCartSkus, canMoveOn, cart: fixedCart };
};
