import { ShopApiDto } from '@b2x/storefront-api-js-client/src/dto';
import classnames from 'classnames';
import React from 'react';

import { Badge } from '../Badge';
import { Button } from '../Button';
import { Col } from '../Col';
import { Div } from '../HTMLElement';
import { t } from '../i18n/i18n';
import { Icon } from '../Icon';
import { Row } from '../Row';
import { GoogleMapInstance } from '../useGoogleMap';
import { usePositionsDistanceCalculator } from '../usePositionsDistanceCalculator';
import { formatShopAddress, getCurrentBreakpoint, stopEventPropagation, uiClassName, untilBreakpoint } from '../util';
import { useDesktopPhoneModal } from './DesktopPhoneModal';
import { ShopThumbnail } from './ShopThumbnail';
import { useStoreLocatorContext } from './StoreLocatorContext';

export interface ShopTileProps {
  activateShop(shop: ShopApiDto | undefined): void;
  activeShopId?: string;
  className?: string;
  currentPosition: GeolocationPosition | undefined;
  googleMap: GoogleMapInstance;
  markerHoveredShopId?: string;
  previewShopId?: string;
  setTileHoveredShopId?: React.Dispatch<React.SetStateAction<string | undefined>>;
  shop: ShopApiDto;
  tileHoveredShopId?: string;
}

export const ShopTile = React.memo(
  ({
    activateShop,
    activeShopId,
    className,
    currentPosition,
    googleMap,
    markerHoveredShopId,
    previewShopId,
    setTileHoveredShopId,
    shop,
    tileHoveredShopId,
  }: ShopTileProps) => {
    const {
      getShopBadges,
      getShopDescription,
      getShopName,
      getShopThumbnailSrc,
      hidePhoneButton,
      iconDirections,
      iconPhoneCall,
      shopTileHeadingSize,
      variantPrimary,
    } = useStoreLocatorContext();

    const handleMouseEnter = React.useCallback(() => {
      setTileHoveredShopId && setTileHoveredShopId(shop.id);
    }, [setTileHoveredShopId, shop.id]);

    const handleMouseLeave = React.useCallback(() => {
      setTileHoveredShopId && setTileHoveredShopId(undefined);
    }, [setTileHoveredShopId]);

    const handleClick = React.useCallback(() => {
      activateShop(shop);
    }, [activateShop, shop]);

    const { lat, lng } = googleMap.getLatLngFromMarker() ?? {};
    const distance = usePositionsDistanceCalculator(
      lat ?? currentPosition?.coords.latitude,
      lng ?? currentPosition?.coords.longitude,
      shop.address?.latitude,
      shop.address?.longitude
    );

    const [DesktopPhoneModal, showDesktopPhoneModal] = useDesktopPhoneModal({
      centered: untilBreakpoint('lg', getCurrentBreakpoint()) ? true : false,
      shop: shop,
      size: 'small',
    });

    return (
      <div
        className={classnames(
          uiClassName({ bs5: 'shop-tile rounded-3 p-3 bg-white' }),
          className,
          shop.id === activeShopId
            ? 'bg-opacity-25' // 'bg-secondary'
            : shop.id === previewShopId
            ? 'bg-opacity-25' // 'bg-secondary bg-opacity-75'
            : shop.id === markerHoveredShopId
            ? 'bg-opacity-25' // 'bg-secondary bg-opacity-50'
            : shop.id === tileHoveredShopId
            ? 'bg-opacity-25' // 'bg-secondary bg-opacity-25'
            : ''
        )}
        onBlur={handleMouseLeave}
        onClick={handleClick}
        onFocus={handleMouseEnter}
        onKeyDown={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        role="button"
        tabIndex={0}
      >
        <Div marginBottom={2}>
          {getShopBadges && (
            <Div marginBottom={2}>
              {getShopBadges(shop).map((badgesProps) => (
                <Badge key={JSON.stringify(badgesProps.children)} {...badgesProps} />
              ))}
            </Div>
          )}
          <Row gap={2}>
            <Col>
              <Row gap={2}>
                {getShopThumbnailSrc && (
                  <Col size={'auto'}>
                    <ShopThumbnail shop={shop} />
                  </Col>
                )}
                <Col className={uiClassName({ bs5: 'd-flex flex-column justify-content-center' })}>
                  <span
                    className={uiClassName({ bs5: `h${shopTileHeadingSize ?? 5} text-${variantPrimary} fw-bold mb-0` })}
                  >
                    {getShopName(shop)}
                  </span>
                  {getShopDescription && (
                    <p className={uiClassName({ bs5: 'lh-sm m-0' })}>
                      <small>{getShopDescription(shop)}</small>
                    </p>
                  )}
                </Col>
              </Row>
            </Col>
            {distance && (
              <Col size={'auto'}>
                <p
                  className={uiClassName({
                    bs5: 'distance badge rounded-pill m-0',
                  })}
                  style={{ fontSize: '0.7rem' }}
                >
                  <strong>{Math.round(distance * 10) / 10} km</strong>
                </p>
              </Col>
            )}
          </Row>
        </Div>
        <Row gap={2}>
          <Col className={uiClassName({ bs5: 'small lh-sm' })} size={''}>
            {shop.address && <p className="m-0 mb-2">{formatShopAddress(shop.address)}</p>}
            {shop.currentTimeRange && (
              <>
                <p
                  className={classnames(
                    'm-0',
                    { 'text-green': shop.currentTimeRange.state === 'OPEN' },
                    { 'text-danger': shop.currentTimeRange.state === 'CLOSED' }
                  )}
                >
                  <Icon className={uiClassName({ bs5: 'me-1 align-top' })} name="clock-stroke" size={14} />
                  {shop.currentTimeRange.state && (
                    <strong>{t(`storeLocator.shopTile.${shop.currentTimeRange.state}`)} • </strong>
                  )}
                  {shop.currentTimeRange.state === 'OPEN' && shop.currentTimeRange.timeSlots && (
                    <>{t('storeLocator.shopTile.closesAtTime', { time: shop.currentTimeRange.timeSlots[0].timeTo })}</>
                  )}
                  {shop.currentTimeRange.state === 'CLOSED' && (
                    <>
                      {shop.nextTimeRange?.dayOfWeek === shop.currentTimeRange.dayOfWeek
                        ? t('storeLocator.shopTile.opensAtTime', {
                            time: shop.nextTimeRange?.timeSlots && shop.nextTimeRange.timeSlots[0].timeFrom,
                          })
                        : shop.nextTimeRange?.dayOfWeek
                        ? t('storeLocator.shopTile.opensAtDayTime', {
                            day: t(`storeLocator.shopDetails.openingDays.${shop.nextTimeRange.dayOfWeek}`),
                            time: shop.nextTimeRange.timeSlots && shop.nextTimeRange.timeSlots[0].timeFrom,
                          })
                        : undefined}
                    </>
                  )}
                </p>
              </>
            )}
          </Col>
          <Col className={uiClassName({ bs5: 'd-flex align-items-end gap-2' })} size={'auto'}>
            {!hidePhoneButton && !!shop.phoneNumber && (
              <>
                {/* eslint-disable-next-line react/forbid-elements */}
                <a
                  className={classnames(uiClassName({ bs5: 'd-block d-lg-none btn btn-secondary p-2' }), {
                    disabled: !shop.phoneNumber,
                  })}
                  href={`tel:${shop.phoneNumber}`}
                  onClick={stopEventPropagation}
                >
                  <Icon name={iconPhoneCall} size={24} />
                </a>
                <Button
                  className={uiClassName({ bs5: 'd-none d-lg-block p-2' })}
                  disabled={!shop.phoneNumber}
                  iconStart={{ name: iconPhoneCall, size: 24 }}
                  onClick={showDesktopPhoneModal}
                  variant="secondary"
                />
              </>
            )}
            {/* eslint-disable-next-line react/forbid-elements */}
            <a
              className={uiClassName({ bs5: 'd-block btn btn-primary p-2' })}
              href={`https://www.google.com/maps/dir/?api=1&destination=${shop.address?.latitude},${shop.address?.longitude}`}
              onClick={stopEventPropagation}
              rel="noreferrer"
              target="_blank"
            >
              <Icon name={iconDirections} size={24} />
            </a>
          </Col>
        </Row>
        {DesktopPhoneModal}
      </div>
    );
  }
);
ShopTile.displayName = 'ShopTile';
