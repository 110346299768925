import React from 'react';

import { ColSize } from './Col';
import { Responsive } from './interfaces';
import { createContext } from './util';

interface RowContextInterface {
  cols?: Responsive<ColSize>;
}

export const [RowContextProvider, useRowContext, useRowContextStrict] =
  createContext<RowContextInterface>('RowContext');

interface UseRowContextInitializerProps {
  cols?: Responsive<ColSize>;
}

const useRowContextInitializer = ({ cols }: UseRowContextInitializerProps) => {
  const rowContext: RowContextInterface = React.useMemo(
    () => ({
      cols,
    }),
    [cols]
  );

  return {
    RowContextProvider,
    rowContext,
  };
};

export interface RowContextProps extends UseRowContextInitializerProps {
  children: React.ReactNode | ((rowContext: RowContextInterface) => React.ReactNode);
}

export const RowContext = ({ children, ...otherProps }: RowContextProps) => {
  const rowContextInitializer = useRowContextInitializer(otherProps);
  return (
    <rowContextInitializer.RowContextProvider value={rowContextInitializer.rowContext}>
      {typeof children === 'function' ? children(rowContextInitializer.rowContext) : children}
    </rowContextInitializer.RowContextProvider>
  );
};
