import { SetRequired } from 'type-fest';

import { VideoContentSectionV1, VideoVariantContentSectionV1 } from './contentTypes';
import { Video, VideoProps, VideoVariant } from './Video';

export interface VideoFromContentV1Props extends VideoContentSectionV1, Omit<VideoProps, 'variants' | 'options'> {
  className?: string;
}

const fromVariantContentSectionToVideoVariant = (
  variantContentSection: SetRequired<VideoVariantContentSectionV1, 'source' | 'width' | 'height'>
): VideoVariant => ({
  height: variantContentSection.height,
  source: {
    bcom: variantContentSection.source.bcom?.file
      ? {
          file: variantContentSection.source.bcom.file,
        }
      : undefined,
    external: variantContentSection.source.external?.file
      ? {
          file: variantContentSection.source.external.file,
        }
      : undefined,
    vimeo: variantContentSection.source.vimeo?.id
      ? {
          id: variantContentSection.source.vimeo.id,
          privacyHash: variantContentSection.source.vimeo.privacyHash,
          title: variantContentSection.source.vimeo.title,
        }
      : undefined,
    youtube: variantContentSection.source.youtube?.id
      ? {
          id: variantContentSection.source.youtube.id,
        }
      : undefined,
  },
  thumbnail: variantContentSection.thumbnail?.url,
  width: variantContentSection.width,
});

export const VideoFromContentV1 = ({ className, options, variants, ...otherVideoProps }: VideoFromContentV1Props) => {
  return (
    <>
      {variants?.xs?.source && variants.xs.width && variants.xs.height && (
        <Video
          {...otherVideoProps}
          className={className}
          options={options}
          variants={{
            lg:
              variants.lg?.source && variants.lg.width && variants.lg.height
                ? fromVariantContentSectionToVideoVariant({
                    height: variants.lg.height,
                    source: variants.lg.source,
                    thumbnail: variants.lg.thumbnail,
                    width: variants.lg.width,
                  })
                : undefined,

            md:
              variants.md?.source && variants.md.width && variants.md.height
                ? fromVariantContentSectionToVideoVariant({
                    height: variants.md.height,
                    source: variants.md.source,
                    thumbnail: variants.md.thumbnail,
                    width: variants.md.width,
                  })
                : undefined,

            sm:
              variants.sm?.source && variants.sm.width && variants.sm.height
                ? fromVariantContentSectionToVideoVariant({
                    height: variants.sm.height,
                    source: variants.sm.source,
                    thumbnail: variants.sm.thumbnail,
                    width: variants.sm.width,
                  })
                : undefined,

            xl:
              variants.xl?.source && variants.xl.width && variants.xl.height
                ? fromVariantContentSectionToVideoVariant({
                    height: variants.xl.height,
                    source: variants.xl.source,
                    thumbnail: variants.xl.thumbnail,
                    width: variants.xl.width,
                  })
                : undefined,

            xs: fromVariantContentSectionToVideoVariant({
              height: variants.xs.height,
              source: variants.xs.source,
              thumbnail: variants.xs.thumbnail,
              width: variants.xs.width,
            }),
            xxl:
              variants.xxl?.source && variants.xxl.width && variants.xxl.height
                ? fromVariantContentSectionToVideoVariant({
                    height: variants.xxl.height,
                    source: variants.xxl.source,
                    thumbnail: variants.xxl.thumbnail,
                    width: variants.xxl.width,
                  })
                : undefined,
          }}
        />
      )}
    </>
  );
};

export const isVideoFromContentValid = (contentSection?: VideoContentSectionV1) =>
  contentSection?.variants?.xs?.width &&
  contentSection.variants.xs.height &&
  contentSection.variants.xs.source &&
  (contentSection.variants.xs.source.bcom?.file ||
    contentSection.variants.xs.source.external?.file ||
    contentSection.variants.xs.source.youtube?.id ||
    contentSection.variants.xs.source.vimeo?.id);
