import classnames from 'classnames';
import React from 'react';

import { Div } from './HTMLElement';
import {
  Responsive,
  ResponsiveAlignContent,
  ResponsiveAlignItems,
  ResponsiveDisplay,
  ResponsiveJustifyContent,
  UiClassName,
} from './interfaces';
import {
  buildAlignContentClassNames,
  buildAlignItemsClassNames,
  buildJustifyContentClassNames,
  renderUI,
} from './util';

export type ColSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'auto' | '';

export type ResponsiveColSize = Responsive<ColSize>;

export interface ColProps extends React.HTMLAttributes<HTMLDivElement> {
  alignContent?: ResponsiveAlignContent;
  alignItems?: ResponsiveAlignItems;
  children?: React.ReactNode;
  display?: ResponsiveDisplay;
  justifyContent?: ResponsiveJustifyContent;
  size?: ResponsiveColSize;
  uiClassName?: UiClassName;
}

export const Col = ({
  alignContent,
  alignItems,
  children,
  className,
  display,
  justifyContent,
  size,
  uiClassName,
  ...otherProps
}: ColProps) => {
  const responsiveSize = typeof size === 'object' ? size : undefined;
  return renderUI({
    bs5: (
      <Div
        {...otherProps}
        className={classnames(
          className,
          'col',
          { [`col-${size}`]: size && typeof size !== 'object' },
          responsiveSize?.xs !== undefined && (responsiveSize.xs !== '' ? `col-${responsiveSize.xs}` : 'col'),
          responsiveSize?.sm !== undefined && (responsiveSize.sm !== '' ? `col-sm-${responsiveSize.sm}` : 'col-sm'),
          responsiveSize?.md !== undefined && (responsiveSize.md !== '' ? `col-md-${responsiveSize.md}` : 'col-md'),
          responsiveSize?.lg !== undefined && (responsiveSize.lg !== '' ? `col-lg-${responsiveSize.lg}` : 'col-lg'),
          responsiveSize?.xl !== undefined && (responsiveSize.xl !== '' ? `col-xl-${responsiveSize.xl}` : 'col-xl'),
          responsiveSize?.xxl !== undefined &&
            (responsiveSize.xxl !== '' ? `col-xxl-${responsiveSize.xxl}` : 'col-xxl'),
          alignItems !== undefined && buildAlignItemsClassNames(alignItems),
          justifyContent !== undefined && buildJustifyContentClassNames(justifyContent),
          alignContent !== undefined && buildAlignContentClassNames(alignContent)
        )}
        display={display}
        uiClassName={uiClassName}
      >
        {children}
      </Div>
    ),
  });
};
