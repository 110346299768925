import { ProductApiDto } from '@b2x/storefront-api-js-client/src';
import { ProductPopulate } from '@b2x/storefront-api-js-client/src/products';
import {
  autoUpdate,
  FloatingPortal,
  offset,
  safePolygon,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react-dom-interactions';
import classnames from 'classnames';
import React from 'react';

import { analytics } from './analytics/analytics';
import { useProductsApi } from './api/useProductsApi';
import { AddToCartFormHelper } from './form/AddToCartForm';
import { ImageFormat } from './interfaces';
import { useListingContextStrict } from './ListingContext';
import { ProductTileBasicInfo } from './ProductTileBasicInfo';
import { ProductTileExtraInfo } from './ProductTileExtraInfo';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface ProductTileProps {
  className?: string;
  colIndex?: number;
  enableAddToCart?: boolean;
  enableExtraInfo?: boolean;
  enableHover?: boolean;
  imageFormat?: ImageFormat | undefined;
  populate?: ProductPopulate;
  preselectedSkuId?: string;
  product: ProductApiDto;
  productsPerRow?: number;
}

const ProductTile = React.memo(
  ({
    className,
    colIndex,
    enableAddToCart = true,
    enableExtraInfo,
    enableHover,
    imageFormat,
    populate,
    preselectedSkuId,
    product: _product,
    productsPerRow,
  }: ProductTileProps) => {
    const [hoverTileOpen, setHoverTileOpen] = React.useState(false);
    const [product, setProduct] = React.useState<ProductApiDto>();

    const { getProduct } = useProductsApi();

    const listingContext = useListingContextStrict();

    if (!listingContext) {
      throw new Error('<ProductTile /> must be inside a <Listing />');
    }

    const setProductDone = React.useRef<boolean>(false);
    React.useEffect(() => {
      if (!setProductDone.current) {
        if (populate !== undefined) {
          getProduct(_product.id, { populate: populate }, { silent: true })
            .then((response) => {
              setProduct(response.data);
            })
            .finally(() => {
              setProductDone.current = true;
            });
        } else {
          setProduct(_product);
        }
      }
    }, [_product, getProduct, populate]);

    const { context, floating, reference, refs, strategy, x, y } = useFloating<HTMLDivElement>({
      middleware: [offset(({ rects }) => -rects.reference.height)],
      onOpenChange: setHoverTileOpen,
      open: hoverTileOpen,
      placement: 'bottom-start',
      whileElementsMounted: autoUpdate,
    });

    const { getFloatingProps, getReferenceProps } = useInteractions([
      useHover(context, {
        delay: 100,
        handleClose: safePolygon(),
        mouseOnly: true,
      }),
    ]);

    const handleLinkClick = React.useCallback(() => {
      if (product) {
        analytics.events.selectProduct('EVENT_ID', product, {
          index: listingContext.getProductIndex(product.id),
          name: listingContext.name,
        });
      }
    }, [listingContext, product]);

    const X = React.useCallback(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ({ fieldsHelper, priceHelper, selectedProductVariant, selectedSku }: any) => (
        <>
          {product && (
            <>
              <ProductTileBasicInfo
                colIndex={colIndex}
                handleLinkClick={handleLinkClick}
                imageFormat={imageFormat}
                product={selectedProductVariant}
                productsPerRow={productsPerRow}
                sku={selectedSku}
              />
              <ProductTileExtraInfo
                colIndex={colIndex}
                enableAddToCart={enableAddToCart}
                fieldsHelper={fieldsHelper}
                handleLinkClick={handleLinkClick}
                priceHelper={priceHelper}
                product={product}
                productsPerRow={productsPerRow}
                selectedProductVariant={selectedProductVariant}
                selectedSku={selectedSku}
              />
            </>
          )}
        </>
      ),
      [colIndex, enableAddToCart, handleLinkClick, imageFormat, product, productsPerRow]
    );

    return (
      <>
        {product && (
          <div
            className={classnames('product-tile', className)}
            {...getReferenceProps(enableHover ? { ref: reference } : undefined)}
          >
            {enableHover ? (
              <>
                <ProductTileBasicInfo
                  colIndex={colIndex}
                  handleLinkClick={handleLinkClick}
                  imageFormat={imageFormat}
                  product={product}
                  productsPerRow={productsPerRow}
                />
                <FloatingPortal>
                  <div
                    className={classnames('product-tile hover', className)}
                    {...getFloatingProps({
                      ref: floating,
                      style: {
                        display: hoverTileOpen ? 'block' : 'none',
                        left: x ?? undefined,
                        position: strategy,
                        top: y ?? undefined,
                        width: refs.reference.current?.getBoundingClientRect().width,
                      },
                    })}
                  >
                    <AddToCartFormHelper preselectedSkuId={preselectedSkuId} product={product} scope="tile">
                      {X}
                    </AddToCartFormHelper>
                  </div>
                </FloatingPortal>
              </>
            ) : (
              <AddToCartFormHelper preselectedSkuId={preselectedSkuId} product={product} scope="tile">
                {X}
              </AddToCartFormHelper>
            )}
          </div>
        )}
      </>
    );
  }
);
ProductTile.displayName = 'ProductTile';

export type ProductTileVariants = '';

const ProductTileController = (props: PropsWithCustomComponent<ProductTileProps>) => (
  <VariantsController<ProductTileProps, ProductTileVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: ProductTile,
      name: 'ProductTile',
    }}
  />
);
export { ProductTileController as ProductTile };
