import { useAppContext, useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { ReferralBoxContentSection } from './contentTypes';
import { t } from './i18n/i18n';
import { ReferralBoxUI } from './ReferralBoxUI';
import { useSharingModal } from './SharingModal';
import { useReferralCode } from './useReferralCode';
import { useShareButtonClickHandler } from './useShareButtonClickHandler';

interface ReferralBoxProps extends ReferralBoxContentSection {}

const ReferralBoxShareButton = ({
  shareCta,
  shareDataTitle,
}: Pick<ReferralBoxProps, 'shareCta' | 'shareDataTitle'>) => {
  const [SharingModal, showSharingModal] = useSharingModal();

  const { referralLink } = useReferralCode();

  const handleShareButtonClick = useShareButtonClickHandler({
    data: {
      title: shareDataTitle,
      url: referralLink,
    },
    fallback: showSharingModal,
  });

  return (
    <>
      <Button
        iconStart={{ name: shareCta?.icon || '', size: 24 }}
        label={shareCta?.label}
        onClick={handleShareButtonClick}
        variant={shareCta?.variant}
      />
      {SharingModal}
    </>
  );
};

export const ReferralBox = ({ shareCta, shareDataTitle, ...props }: ReferralBoxProps) => {
  const { session } = useAppContext();
  const isLoggedIn = !!session?.customer;

  const { onLoginActionCallback } = useAppStaticContext();

  return (
    <>
      <ReferralBoxUI {...props}>
        {isLoggedIn ? (
          <ReferralBoxShareButton shareCta={shareCta} shareDataTitle={shareDataTitle} />
        ) : (
          <Button onClick={onLoginActionCallback}>{t('misc.loginRegister')}</Button>
        )}
      </ReferralBoxUI>
    </>
  );
};
