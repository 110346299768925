import {
  AddAssembledToCartRequestApiDto,
  AddCouponToCartRequestApiDto,
  // eslint-disable-next-line no-restricted-imports
  api,
  CartSkuApiDto,
  ChangeCartSkuRequestApiDto,
  ErrorResponseApiDto,
  GiftCardRequestApiDto,
  MoveCartSkuToWishlistRequestApiDto,
  ProductApiDto,
  SetAddressesToCartRequestApiDto,
  SetAddressToCartRequestApiDto,
  SetEmailOnCartRequestApiDto,
  SetPaymentMethodOnCartRequestApiDto,
  SetShippingProfileOnCartRequestApiDto,
  SkuApiDto,
  SkuToCartRequestApiDto,
} from '@b2x/storefront-api-js-client/src';
import { GetCartOptions, GetOfferYProductOptions } from '@b2x/storefront-api-js-client/src/cart';
import { AxiosError } from 'axios';
import React from 'react';

import { analytics } from '../analytics/analytics';
import { useAppStaticContext } from '../AppContext';
import { appConfig } from '../config';
import { env } from '../env';
import { t } from '../i18n/i18n';
import { useModals } from '../useModals';
import { ApiRequestConfig, useApiRequest } from './useApiRequest';
import { useCustomerApi } from './useCustomerApi';
import { useSessionApi } from './useSessionApi';

export const useCartApi = () => {
  const { apiRequest } = useApiRequest();
  const { getSession } = useSessionApi();
  const { setSession } = useAppStaticContext();
  const { showConfirm } = useModals();

  const getCart = React.useCallback(
    (options?: GetCartOptions, config?: ApiRequestConfig) =>
      apiRequest(api.cart.get(options), { silent: false, ...config }),
    [apiRequest]
  );

  const updateSessionCart = React.useCallback(
    (config: ApiRequestConfig) =>
      getSession(
        {
          ...appConfig.api?.sessionOptions,
          populate: {
            cart: appConfig.api?.sessionOptions?.populate?.cart,
          },
        },
        config
      ).then((response) => {
        setSession((prevState) => (prevState ? { ...prevState, cart: response.data.cart } : undefined));
        return response;
      }),
    [getSession, setSession]
  );

  // const addSkus = React.useCallback(
  //   (data: AddToCartRequestApiDto, config?: ApiRequestConfig) =>
  //     apiRequest(api.cart.add(data), { silent: false, ...config }).then((response) => {
  //       updateSessionCart({ silent: false });
  //       return response;
  //     }),
  //   [apiRequest, updateSessionCart]
  // );

  const addSkus = React.useCallback(
    (
      data: Array<{
        giftCard?: GiftCardRequestApiDto;
        offerId?: string;
        product: ProductApiDto;
        quantity: number;
        sku: SkuApiDto;
      }>,
      config?: ApiRequestConfig
    ) => {
      if (data.some(({ sku }) => sku.price === undefined)) {
        throw new Error('API - addSku - missing sku.price');
      }
      return apiRequest(
        api.cart.add({
          force: false,
          simulateCartConstraintsViolation: env.REACT_APP_SIMULATE_CART_CONSTRAINTS_VIOLATION,
          skus: data.map(
            ({ giftCard, offerId, product, quantity, sku }) =>
              ({
                giftCard: giftCard,
                id: sku.id,
                offerId: offerId,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                price: sku.price!.value,
                quantity: quantity,
              } satisfies SkuToCartRequestApiDto)
          ),
        }),
        { silent: false, suppressErrorModal: ['api.error.AddingSkuNotCompatible'], ...config }
      )
        .then((response) =>
          updateSessionCart({ silent: false }).then(() => {
            return response;
          })
        )
        .catch((error: AxiosError<ErrorResponseApiDto>) => {
          if (error.response?.data.key === 'api.error.AddingSkuNotCompatible') {
            return showConfirm({
              cancelLabel: t('form.addToCartForm.outcome.failure.AddingSkuNotCompatible.buttons.no.label'),
              children: t('form.addToCartForm.outcome.failure.AddingSkuNotCompatible.body'),
              confirmLabel: t('form.addToCartForm.outcome.failure.AddingSkuNotCompatible.buttons.yes.label'),
              title: t('form.addToCartForm.outcome.failure.AddingSkuNotCompatible.title'),
            })
              .then(() => {
                return apiRequest(
                  api.cart.add({
                    force: true,
                    simulateCartConstraintsViolation: env.REACT_APP_SIMULATE_CART_CONSTRAINTS_VIOLATION,
                    skus: data.map(({ giftCard, product, quantity, sku }) => ({
                      giftCard: giftCard,
                      id: sku.id,
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      price: sku.price!.value,
                      quantity: quantity,
                    })),
                  }),
                  { silent: false, ...config }
                ).then((response) =>
                  updateSessionCart({ silent: false }).then(() => {
                    return response;
                  })
                );
              })
              .catch(() => {
                throw error;
              });
          } else {
            throw error;
          }
        });
    },
    [apiRequest, showConfirm, updateSessionCart]
  );

  const addAssembledSku = React.useCallback(
    (
      data: Omit<AddAssembledToCartRequestApiDto, 'force' | 'simulateCartConstraintsViolation'>,
      config?: ApiRequestConfig
    ) => {
      // if (sku.price === undefined) {
      //   throw new Error('API - addSku - missing sku.price');
      // }
      return apiRequest(
        api.cart.addAssembled({
          ...data,
          force: false,
          simulateCartConstraintsViolation: env.REACT_APP_SIMULATE_CART_CONSTRAINTS_VIOLATION,
        }),
        {
          silent: false,
          suppressErrorModal: ['api.error.AddingSkuNotCompatible'],
          ...config,
        }
      )
        .then((response) =>
          updateSessionCart({ silent: false }).then(() => {
            return response;
          })
        )
        .catch((error: AxiosError<ErrorResponseApiDto>) => {
          if (error.response?.data.key === 'api.error.AddingSkuNotCompatible') {
            return showConfirm({
              cancelLabel: t('form.addToCartForm.outcome.failure.AddingSkuNotCompatible.buttons.no.label'),
              children: t('form.addToCartForm.outcome.failure.AddingSkuNotCompatible.body'),
              confirmLabel: t('form.addToCartForm.outcome.failure.AddingSkuNotCompatible.buttons.yes.label'),
              title: t('form.addToCartForm.outcome.failure.AddingSkuNotCompatible.title'),
            })
              .then(() => {
                // if (sku.price === undefined) {
                //   throw new Error('API - addSku - missing sku.price');
                // }
                return apiRequest(
                  api.cart.addAssembled({
                    ...data,
                    force: true,
                    simulateCartConstraintsViolation: env.REACT_APP_SIMULATE_CART_CONSTRAINTS_VIOLATION,
                  }),
                  {
                    silent: false,
                    ...config,
                  }
                ).then((response) =>
                  updateSessionCart({ silent: false }).then(() => {
                    return response;
                  })
                );
              })
              .catch(() => {
                throw error;
              });
          } else {
            throw error;
          }
        });
    },
    [apiRequest, showConfirm, updateSessionCart]
  );

  const changeSkuQuantity = React.useCallback(
    (data: ChangeCartSkuRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.cart.changeQuantity(data), { silent: false, ...config }).then((response) => {
        updateSessionCart({ silent: false });
        return response;
      }),
    [apiRequest, updateSessionCart]
  );

  const removeSku = React.useCallback(
    ({ cartSku }: { cartSku: CartSkuApiDto }, config?: ApiRequestConfig) =>
      apiRequest(api.cart.remove(cartSku.id), { silent: false, ...config }).then((response) => {
        analytics.events.removeFromCart(response.eventId, cartSku);
        updateSessionCart({ silent: false });
        return response;
      }),
    [apiRequest, updateSessionCart]
  );

  const getCoupons = React.useCallback(
    (config?: ApiRequestConfig) => apiRequest(api.cart.getCoupons(), { ...config }),
    [apiRequest]
  );

  const applyCoupon = React.useCallback(
    (data: AddCouponToCartRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.cart.applyCoupon(data), { silent: false, ...config }).then((response) => {
        updateSessionCart({ silent: false });
        return response;
      }),
    [apiRequest, updateSessionCart]
  );

  const removeCoupon = React.useCallback(
    (config?: ApiRequestConfig) =>
      apiRequest(api.cart.removeCoupon(), { silent: false, ...config }).then((response) => {
        updateSessionCart({ silent: false });
        return response;
      }),
    [apiRequest, updateSessionCart]
  );

  const getShippingProfiles = React.useCallback(
    (config?: ApiRequestConfig) => apiRequest(api.cart.getShippingProfiles(), { ...config }),
    [apiRequest]
  );

  const setShippingProfile = React.useCallback(
    (data: SetShippingProfileOnCartRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.cart.setShippingProfile(data), { silent: false, ...config }).then((response) =>
        updateSessionCart({ silent: false }).then(() => {
          return response;
        })
      ),
    [apiRequest, updateSessionCart]
  );

  const getPaymentMethods = React.useCallback(
    (config?: ApiRequestConfig) => apiRequest(api.cart.getPaymentMethods(), { ...config }),
    [apiRequest]
  );

  const setPaymentMethod = React.useCallback(
    (data: SetPaymentMethodOnCartRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.cart.setPaymentMethod(data), { silent: false, ...config }).then((response) =>
        updateSessionCart({ silent: false }).then(() => {
          return response;
        })
      ),
    [apiRequest, updateSessionCart]
  );

  const setEmail = React.useCallback(
    (data: SetEmailOnCartRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.cart.setEmail(data), { silent: false, ...config }).then((response) => {
        updateSessionCart({ silent: false });
        return response;
      }),
    [apiRequest, updateSessionCart]
  );

  const setDefaultCustomerData = React.useCallback(
    (config?: ApiRequestConfig) =>
      apiRequest(api.cart.setDefaultCustomerData(), { silent: false, ...config }).then((response) => {
        updateSessionCart({ silent: false });
        return response;
      }),
    [apiRequest, updateSessionCart]
  );

  const setShippingAddress = React.useCallback(
    (data: SetAddressToCartRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.cart.setShippingAddress(data), { silent: false, ...config }).then((response) => {
        updateSessionCart({ silent: false });
        return response;
      }),
    [apiRequest, updateSessionCart]
  );

  const setBillingAddress = React.useCallback(
    (data: SetAddressToCartRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.cart.setBillingAddress(data), { silent: false, ...config }).then((response) => {
        updateSessionCart({ silent: false });
        return response;
      }),
    [apiRequest, updateSessionCart]
  );

  const setAddresses = React.useCallback(
    (data: SetAddressesToCartRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.cart.setAddresses(data), { silent: false, ...config }).then((response) => {
        updateSessionCart({ silent: false });
        return response;
      }),
    [apiRequest, updateSessionCart]
  );

  const { updateSessionWishlist } = useCustomerApi();

  const moveToWishlist = React.useCallback(
    (data: MoveCartSkuToWishlistRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.cart.moveToWishlist(data), { silent: false, ...config }).then((response) => {
        updateSessionCart({ silent: false });
        updateSessionWishlist();
        return response;
      }),
    [apiRequest, updateSessionCart, updateSessionWishlist]
  );

  const applyGiftCard = React.useCallback(
    (code: string, config?: ApiRequestConfig) =>
      apiRequest(api.cart.applyGiftCard(code), { silent: false, ...config }).then((response) => {
        updateSessionCart({ silent: false });
        return response;
      }),
    [apiRequest, updateSessionCart]
  );

  const removeGiftCard = React.useCallback(
    (code: string, config?: ApiRequestConfig) =>
      apiRequest(api.cart.removeGiftCard(code), { silent: false, ...config }).then((response) => {
        updateSessionCart({ silent: false });
        return response;
      }),
    [apiRequest, updateSessionCart]
  );

  const getOfferYProducts = React.useCallback(
    <T>(offerId: string, options?: GetOfferYProductOptions, config?: ApiRequestConfig) =>
      apiRequest(api.cart.getOfferYProducts<T>(offerId, options), { ...config }),
    [apiRequest]
  );

  return {
    addAssembledSku,
    addSkus,
    applyCoupon,
    applyGiftCard,
    changeSkuQuantity,
    getCart,
    getCoupons,
    getOfferYProducts,
    getPaymentMethods,
    getShippingProfiles,
    moveToWishlist,
    removeCoupon,
    removeGiftCard,
    removeSku,
    setAddresses,
    setBillingAddress,
    setDefaultCustomerData,
    setEmail,
    setPaymentMethod,
    setShippingAddress,
    setShippingProfile,
    updateSessionCart,
  };
};
