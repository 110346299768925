import classnames from 'classnames';
import React from 'react';

import { useEqualHeightContextStrict } from './EqualHeightContext';
// import { useStatePromise } from 'use-state-promise';
import { AspectRatio, ImagePlaceholder, ImagePlaceholderProps } from './ImagePlaceholder';
import { Breakpoint, ImageFormat } from './interfaces';
import { getBreakpointValue, getImageSrc, renderUI, uiClassName, wait } from './util';

export type Exclude2x = Partial<Record<Breakpoint, boolean>>;

export interface ImageProps extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'> {
  // alt?: string;
  aspectRatio?: number | AspectRatio;
  // className?: string;
  exclude2x?: boolean | Exclude2x;
  fluid?: boolean;
  format?: ImageFormat;
  // fromAdmin?: boolean;
  // height?: number | string;
  placeHolder?: ImagePlaceholderProps;
  src?: string | Src;
  // title?: string;
  // width?: number | string;
}

export interface Src {
  lg?: string;
  md?: string;
  sm?: string;
  xl?: string;
  xs: string;
  xxl?: string;
}

export const Image = ({
  aspectRatio,
  className,
  exclude2x = true,
  fluid,
  // fromAdmin = false,
  format,
  onLoad,
  placeHolder,
  src,
  ...otherProps
}: ImageProps) => {
  const sourcesBreakpoint: Array<keyof Src> = ['xxl', 'xl', 'lg', 'md', 'sm'];
  const [loaded, setLoaded] = React.useState<boolean>(false);

  const equalHeightContext = useEqualHeightContextStrict();

  const getImage = React.useCallback(
    (path: string, density = 1) => {
      return getImageSrc(path, format ?? (`@${density}x` as ImageFormat));
    },
    [format]
  );

  const getImageFromBreakpoint = React.useCallback(
    (breakpoint: keyof Src, density = 1) => {
      if (typeof src === 'string') {
        throw new Error(
          'Trying to get image source from breakpoint, but the src is a simple string (non-responsive image)'
        );
      }
      const breakpointSrc = src && src[breakpoint];
      return breakpointSrc && getImage(breakpointSrc, density);
    },
    [getImage, src]
  );

  const getDensities = React.useCallback(
    (breakpoint?: keyof Src): Array<number> => {
      let densities: Array<number> = [1];
      if (typeof exclude2x === 'boolean') {
        densities = exclude2x ? [1] : [1, 1.5, 2];
      } else {
        if (!breakpoint) {
          throw Error('Image.tsx. Something is wrong, cannot calculate densities without breakpoint.');
        }
        densities = exclude2x[breakpoint] ? [1] : [1, 1.5, 2];
      }
      return densities;
    },
    [exclude2x]
  );

  const getSrcSet = React.useCallback(
    (path: string) => {
      const densities = getDensities();
      return densities.map((density) => `${getImage(path, density)} ${density}x`).join(', ');
    },
    [getDensities, getImage]
  );

  const getSrcSetFromBreakpoint = React.useCallback(
    (breakpoint: keyof Src) => {
      const densities = getDensities(breakpoint);
      return densities.map((density) => `${getImageFromBreakpoint(breakpoint, density)} ${density}x`).join(', ');
    },
    [getDensities, getImageFromBreakpoint]
  );

  const handleLoad = React.useCallback(
    (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
      setLoaded(true);
      wait(100).then(() => {
        equalHeightContext && equalHeightContext.updateTrigger();
        onLoad && onLoad(event);
      });
    },
    [equalHeightContext, onLoad]
  );

  return (
    <>
      {aspectRatio && !loaded && (
        <ImagePlaceholder aspectRatio={aspectRatio} className="img-placeholder" label="" {...placeHolder} />
      )}
      <picture className={classnames({ [uiClassName({ bs5: 'd-none' })]: !loaded })}>
        {typeof src === 'object' &&
          sourcesBreakpoint.map(
            (sourceBreakpoint) =>
              getImageFromBreakpoint(sourceBreakpoint) && (
                <source
                  key={sourceBreakpoint}
                  media={`(min-width: ${getBreakpointValue(sourceBreakpoint)}px)`}
                  srcSet={getSrcSetFromBreakpoint(sourceBreakpoint)}
                />
              )
          )}
        {renderUI({
          bs5: (
            // eslint-disable-next-line react/forbid-elements, jsx-a11y/alt-text
            <img
              {...otherProps}
              className={classnames({ 'img-fluid': fluid }, className)}
              onLoad={handleLoad}
              src={
                typeof src === 'object'
                  ? getImageFromBreakpoint('xs')
                  : typeof src === 'string'
                  ? getImage(src)
                  : undefined
              }
              srcSet={
                typeof src === 'object'
                  ? getSrcSetFromBreakpoint('xs')
                  : typeof src === 'string'
                  ? getSrcSet(src)
                  : undefined
              }
            />
          ),
        })}
      </picture>
    </>
  );
};
