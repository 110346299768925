import './CartOffcanvas.scss';

import { b2x } from '@b2x/react/src';

import { MiscellaneousContentType } from './contentTypes';

export interface CartOffcanvasProps extends b2x.OffcanvasProps {}

const CartOffcanvas = ({ ...offcanvasProps }: CartOffcanvasProps) => {
  const content = b2x.useContent<MiscellaneousContentType>('MISCELLANEOUS_CONTENT');

  return (
    <b2x.Offcanvas {...offcanvasProps} className="cart-offcanvas large-layout" placement="right">
      {({ close }) => <b2x.CartOffcanvas close={close} footerMessage={content?.body.cartOffcanvasMessage} />}
    </b2x.Offcanvas>
  );
};

export const useCartOffcanvas = (props?: CartOffcanvasProps) => b2x.useStaticModal(CartOffcanvas, 'offcanvas', props);
