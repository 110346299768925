import classnames from 'classnames';
import { FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { useCustomerApi } from '../api/useCustomerApi';
import { Button } from '../Button';
import { appConfig } from '../config';
import { t } from '../i18n/i18n';
import { useNavigate, useSearchParams } from '../router/router';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { useModals } from '../useModals';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { PasswordInput, PasswordInputProps } from './fields/Input';
import { FormButtonProps, formikString, getInitialString, isResetButtonDisabled, isSubmitButtonDisabled } from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';

export interface ChangePasswordByTokenFormProps
  extends BaseHelpedFormProps<FormValues, FieldsHelper, ValidationSchemaSelector> {}

interface FormValues {
  confirmNewPassword: formikString;
  newPassword: formikString;
  token: formikString;
}

type ValidationSchema = {
  confirmNewPassword: yup.StringSchema;
  newPassword: yup.StringSchema;
};

interface ValidationSchemaSelector {}

interface FieldsHelper {
  buttons: {
    cancel?: FormButtonProps;
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  confirmNewPassword: { formGroup: FormGroupProps; passwordInput: PasswordInputProps };
  newPassword: { formGroup: FormGroupProps; passwordInput: PasswordInputProps };
}

export const ChangePasswordByTokenFormHelper = ({
  children,
  className,
  initialValues,
  onCancel,
  onSuccess,
  ...otherProps
}: ChangePasswordByTokenFormProps) => {
  const { showModal } = useModals();
  const [searchParams] = useSearchParams();
  const { changePasswordByToken } = useCustomerApi();
  const navigate = useNavigate();

  // "token" usato da bcom, "pwrt" usato da gigya
  const token = searchParams.get('token') ?? searchParams.get('pwrt') ?? undefined;

  if (token === undefined) {
    throw new Error('Missing token');
  }

  const _initialValues = React.useMemo<FormValues>(
    () => ({
      confirmNewPassword: getInitialString(),
      newPassword: getInitialString(),
      token: token,
      ...initialValues,
    }),
    [initialValues, token]
  );

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      confirmNewPassword: yup
        .string()
        .required()
        .oneOf([yup.ref('newPassword'), null]),
      newPassword: yup.string().required(),
    }),
    []
  );

  const handleSubmit = React.useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) =>
      changePasswordByToken(values).then(() => {
        onSuccess
          ? onSuccess()
          : showModal({
              children: t('form.changePasswordByTokenForm.outcome.success.body'),
              onClose: () => navigate('/'),
              title: t('form.changePasswordByTokenForm.outcome.success.title'),
            });
      }),
    [changePasswordByToken, navigate, onSuccess, showModal]
  );

  const ref = React.useRef<HTMLFormElement>(null);

  const { insideModal } = useInsideModalDetector();
  const closeModal = useModalCloser();

  return (
    <HelpedForm<FormValues>
      className={classnames('ChangePasswordByTokenForm', className)}
      initialValues={_initialValues}
      innerRef={ref}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      {...otherProps}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper = {
          buttons: {
            cancel: onCancel
              ? {
                  label: t('form.loginForm.buttons.cancel.label'),
                  onClick: onCancel,
                  type: 'button',
                  variant: appConfig.form?.buttons.cancel?.defaultVariant,
                }
              : undefined,
            reset: {
              disabled: isResetButtonDisabled(formik),
              label: t('form.changePasswordByTokenForm.buttons.reset.label'),
              type: 'reset',
              variant: appConfig.form?.buttons.cancel?.defaultVariant,
            },
            submit: {
              disabled: isSubmitButtonDisabled(formik),
              label: t('form.changePasswordByTokenForm.buttons.submit.label'),
              type: 'submit',
              variant: appConfig.form?.buttons.submit?.defaultVariant,
            },
          },
          confirmNewPassword: {
            formGroup: {
              label: t('form.changePasswordByTokenForm.fields.confirmNewPassword.label'),
              names: ['confirmNewPassword'],
            },
            passwordInput: {
              autoComplete: 'new-password',
              name: 'confirmNewPassword',
              placeholder: t('form.changePasswordByTokenForm.fields.confirmNewPassword.placeholder'),
            },
          },
          newPassword: {
            formGroup: { label: t('form.changePasswordByTokenForm.fields.newPassword.label'), names: ['newPassword'] },
            passwordInput: {
              autoComplete: 'new-password',
              name: 'newPassword',
              placeholder: t('form.changePasswordByTokenForm.fields.newPassword.placeholder'),
            },
          },
        };
        return children ? (
          children({ closeModal, fieldsHelper, formik, insideModal })
        ) : (
          <>
            <FormGroup {...fieldsHelper.newPassword.formGroup}>
              <PasswordInput {...fieldsHelper.newPassword.passwordInput} />
            </FormGroup>
            <FormGroup {...fieldsHelper.confirmNewPassword.formGroup}>
              <PasswordInput {...fieldsHelper.confirmNewPassword.passwordInput} />
            </FormGroup>
            <Button {...fieldsHelper.buttons.submit} />
          </>
        );
      }}
    </HelpedForm>
  );
};

export type ChangePasswordByTokenFormVariants = '';

const ChangePasswordByTokenFormController = (
  props: PropsWithCustomComponentWithoutChildren<ChangePasswordByTokenFormProps>
) => (
  <VariantsController<ChangePasswordByTokenFormProps, ChangePasswordByTokenFormVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: ChangePasswordByTokenFormHelper,
      name: 'ChangePasswordByTokenForm',
    }}
  />
);
export { ChangePasswordByTokenFormController as ChangePasswordByTokenForm };
