import { Button } from '../Button';
import { t } from '../i18n/i18n';
import { TextInput } from './fields/Input';
import { FormGroup } from './FormGroup';
import { OrderGuestFormHelper, OrderGuestFormProps } from './OrderGuestForm';

export const OrderGuestFormA = (props: OrderGuestFormProps) => (
  <OrderGuestFormHelper {...props}>
    {({ fieldsHelper }) => (
      <>
        <div className="row">
          <div className="col-12 col-md-5">
            <FormGroup {...fieldsHelper.email.formGroup}>
              <TextInput {...fieldsHelper.email.textInput} />
            </FormGroup>
          </div>
          <div className="col-12 col-md-5">
            <FormGroup {...fieldsHelper.code.formGroup} className="custom-icon-btn-group">
              <TextInput {...fieldsHelper.code.textInput} />
            </FormGroup>
          </div>
          <div className="col-12 col-md-2 pb-3">
            <div className="d-grid align-items-end h-100">
              <Button {...fieldsHelper.buttons.submit} />
            </div>
          </div>
        </div>
      </>
    )}
  </OrderGuestFormHelper>
);
