import React from 'react';

import { ModalProps, PackedModalProps } from './Modal';
import { Portal } from './Portal';
import { useModals } from './useModals';
import { wait } from './util';

export const useStaticModal = <T extends ModalProps>(
  Modal: React.FunctionComponent<T>,
  type: 'modal' | 'offcanvas',
  modalProps?: T
) => {
  const [ref, showModalWithRef, closeModalWithRef] = useModalByRef(type);

  const x = (modalProps ?? {}) as T;

  const ModalWithRef = (
    <Portal>
      <Modal {...x} innerRef={ref} />
    </Portal>
  );

  return [ModalWithRef, showModalWithRef, closeModalWithRef] as const;
};

export const useDynamicModal = <T extends ModalProps>(
  Modal: React.FunctionComponent<T>,
  type: 'modal' | 'offcanvas',
  remountAtPropsChange?: boolean
) => {
  const [innerProps, setInnerProps] = React.useState<T>();

  const ref = React.useRef(null);
  const { closeByRef, showByRef } = useModals();

  const ModalWithRef = <Portal>{innerProps && <Modal {...innerProps} innerRef={ref} />}</Portal>;

  const showModalWithRef = React.useCallback(
    (props: T) => {
      if (remountAtPropsChange) {
        setInnerProps(undefined);
        wait(100).then(() => {
          setInnerProps(props);
        });
      } else {
        setInnerProps(props);
      }
    },
    [remountAtPropsChange]
  );

  React.useEffect(() => {
    if (innerProps) {
      showByRef(ref, type);
    }
  }, [innerProps, showByRef, type]);

  const closeModalWithRef = React.useCallback(() => {
    closeByRef(ref);
  }, [closeByRef]);

  return [ModalWithRef, showModalWithRef, closeModalWithRef] as const;
};

// export const useModal = (props: PackedModalProps) => {
//   const { showModal: _showModal } = useModals();

//   const showModal = React.useCallback(() => {
//     _showModal(props);
//   }, [_showModal, props]);

//   return showModal;
// };

// export interface UseFormModalProps<FormProps> {
//   formProps?: FormProps;
//   modalProps?: Partial<PackedModalProps>;
// }

// // eslint-disable-next-line @typescript-eslint/naming-convention
// interface _UseFormModalProps<FormProps> {
//   Form: React.FunctionComponent<FormProps>;
//   formProps: FormProps;
//   modalProps: Omit<PackedModalProps, 'id'>;
// }

// // eslint-disable-next-line @typescript-eslint/ban-types
// export const useFormModal = <FormProps extends object>({
//   Form,
//   formProps,
//   modalProps,
// }: _UseFormModalProps<FormProps>) => {
//   const { showModal } = useModals();
//   return React.useCallback(() => {
//     showModal({
//       children: <Form {...formProps} />,
//       ...modalProps,
//     });
//   }, [Form, formProps, modalProps, showModal]);
// };

export const useModal = () => {
  const { close: _close, showModal } = useModals();

  const id = React.useRef<string>();

  const show = React.useCallback(
    (props: Omit<PackedModalProps, 'id'>) => {
      id.current = showModal(props);
    },
    [showModal]
  );

  const close = React.useCallback(() => {
    return new Promise<void>((resolve) => {
      if (id.current) {
        return _close(id.current);
      } else {
        resolve();
      }
    });
  }, [_close]);

  return [show, close] as const;
};

export const useModalByRef = (type: 'modal' | 'offcanvas') => {
  const ref = React.useRef(null);

  const { closeByRef, showByRef } = useModals();

  const show = React.useCallback(() => {
    showByRef(ref, type);
  }, [showByRef, type]);

  const close = React.useCallback(() => {
    closeByRef(ref);
  }, [closeByRef]);

  return [ref, show, close] as const;
};
