import { ProductApiDto, SkuApiDto } from '@b2x/storefront-api-js-client/src';

import { Button } from './Button';
import { EqualHeightElement } from './EqualHeightElement';
import { AddToCartFieldsHelper } from './form/AddToCartForm';
import { Radio } from './form/fields/RadioCheck';
import { FormGroup } from './form/FormGroup';
import { t } from './i18n/i18n';
import { UsePriceResult } from './usePrice';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface ProductTileExtraInfoProps {
  colIndex?: number;
  enableAddToCart?: boolean;
  fieldsHelper: AddToCartFieldsHelper;
  handleLinkClick(): void;
  priceHelper?: UsePriceResult;
  product: ProductApiDto;
  productsPerRow?: number;
  selectedProductVariant?: ProductApiDto;
  selectedSku?: SkuApiDto;
}

const ProductTileExtraInfo = ({
  enableAddToCart,
  fieldsHelper,
  priceHelper,
  productsPerRow,
}: ProductTileExtraInfoProps) => {
  return (
    <div className="product-tile-extra-info">
      <EqualHeightElement name={`product-row-extra-info--${productsPerRow}`}>
        <FormGroup {...fieldsHelper.productVariants.formGroup} label={undefined}>
          {fieldsHelper.productVariants.formFields.map((formField) => (
            <Radio key={formField.productVariant.id} {...formField.radio} inline />
          ))}
        </FormGroup>
        {fieldsHelper.skuAttributes.map(({ attribute, formGroup, radios }) => (
          <FormGroup
            {...formGroup}
            key={attribute.title}
            label={attribute.title + (attribute.value ?? t('product.selectSize'))}
          >
            {radios.map(({ radio }) => (
              <Radio key={radio.id} {...radio} inline />
            ))}
          </FormGroup>
        ))}
      </EqualHeightElement>
      {enableAddToCart && <Button {...fieldsHelper.buttons.submit} variant="primary" />}
    </div>
  );
};

export type ProductTileExtraInfoVariants = '';

const ProductTileExtraInfoController = (props: PropsWithCustomComponent<ProductTileExtraInfoProps>) => (
  <VariantsController<ProductTileExtraInfoProps, ProductTileExtraInfoVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: ProductTileExtraInfo,
      name: 'ProductTileExtraInfo',
    }}
  />
);
export { ProductTileExtraInfoController as ProductTileExtraInfo };
