import {
  AddAssembledToCartRequestApiDto,
  AddCouponToCartRequestApiDto,
  AddToCartRequestApiDto,
  CartApiDto,
  ChangeCartSkuRequestApiDto,
  CouponApiDto,
  MoveCartSkuToWishlistRequestApiDto,
  PaymentMethodApiDto,
  ProductApiDto,
  SetAddressesToCartRequestApiDto,
  SetAddressToCartRequestApiDto,
  SetEmailOnCartRequestApiDto,
  SetPaymentMethodOnCartRequestApiDto,
  SetShippingProfileOnCartRequestApiDto,
  ShippingProfileApiDto,
} from './dto';
import { ProductPopulate, SkuPopulate } from './products';
import { apiRequest, BaseOptions, getBaseOptions, Populate } from './utils/util';

export interface CartPopulate {
  alerts?: boolean;
  availableCoupons?: boolean;
  billingAddress?: boolean;
  campaigns?: boolean;
  depositPayments?: boolean;
  offers?: boolean;
  packs?: Populate<ShippingPackPopulate>;
  paymentMethod?: boolean;
  shippingAddress?: boolean;
  shippingProfile?: boolean;
}

export interface ShippingPackPopulate {
  alerts?: boolean;
  cartSkus?: Populate<CartSkuPopulate>;
}

export interface CartSkuPopulate {
  alerts?: boolean;
  assembledComponents?: Populate<CartSkuPopulate>;
  giftCard?: boolean;
  offers?: boolean;
  sku?: Populate<SkuPopulate>;
}

const rootPath = '/v2/cart';

export interface GetCartOptions extends BaseOptions {
  populate: Populate<CartPopulate>;
}

export const get = async (options?: GetCartOptions) =>
  apiRequest<CartApiDto, void, CartPopulate>({
    ...getBaseOptions(options),
    populate: options?.populate,
    url: `${rootPath}`,
  });

export const add = async (data: AddToCartRequestApiDto, options?: BaseOptions) =>
  apiRequest<void, AddToCartRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'POST',
    url: `${rootPath}/sku`,
  });

export const changeQuantity = async (data: ChangeCartSkuRequestApiDto, options?: BaseOptions) =>
  apiRequest<void, ChangeCartSkuRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'PUT',
    url: `${rootPath}/sku`,
  });

export const remove = async (id: string, options?: BaseOptions) =>
  apiRequest<void, AddToCartRequestApiDto, void>({
    ...getBaseOptions(options),
    method: 'DELETE',
    url: `${rootPath}/sku/${id}`,
  });

const couponsPath = `${rootPath}/coupons`;

export const getCoupons = async (options?: BaseOptions) =>
  apiRequest<Array<CouponApiDto>, void, void>({
    ...getBaseOptions(options),
    url: `${couponsPath}`,
  });

export const applyCoupon = async (data: AddCouponToCartRequestApiDto, options?: BaseOptions) =>
  apiRequest<void, AddCouponToCartRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'POST',
    url: `${couponsPath}`,
  });

export const removeCoupon = async (options?: BaseOptions) =>
  apiRequest<void, AddCouponToCartRequestApiDto, void>({
    ...getBaseOptions(options),
    method: 'DELETE',
    url: `${couponsPath}`,
  });

const shippingProfilesPath = `${rootPath}/shippingProfiles`;

export const getShippingProfiles = async (options?: BaseOptions) =>
  apiRequest<Array<ShippingProfileApiDto>, void, void>({
    ...getBaseOptions(options),
    url: `${shippingProfilesPath}`,
  });

export const setShippingProfile = async (data: SetShippingProfileOnCartRequestApiDto, options?: BaseOptions) =>
  apiRequest<void, SetShippingProfileOnCartRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'PUT',
    url: `${shippingProfilesPath}`,
  });

const paymentMethodsPath = `${rootPath}/paymentMethods`;

export const getPaymentMethods = async (options?: BaseOptions) =>
  apiRequest<Array<PaymentMethodApiDto>, void, void>({
    ...getBaseOptions(options),
    url: `${paymentMethodsPath}`,
  });

export const setPaymentMethod = async (data: SetPaymentMethodOnCartRequestApiDto, options?: BaseOptions) =>
  apiRequest<void, SetPaymentMethodOnCartRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'PUT',
    url: `${paymentMethodsPath}`,
  });

export const setEmail = async (data: SetEmailOnCartRequestApiDto, options?: BaseOptions) =>
  apiRequest<void, SetEmailOnCartRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'PUT',
    url: `${rootPath}/email`,
  });

export const setDefaultCustomerData = async (options?: BaseOptions) =>
  apiRequest<void, void, void>({
    ...getBaseOptions(options),
    method: 'PUT',
    url: `${rootPath}/customerData`,
  });

export const setShippingAddress = async (data: SetAddressToCartRequestApiDto, options?: BaseOptions) =>
  apiRequest<void, SetAddressToCartRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'PUT',
    url: `${rootPath}/address/shipping`,
  });

export const setBillingAddress = async (data: SetAddressToCartRequestApiDto, options?: BaseOptions) =>
  apiRequest<void, SetAddressToCartRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'PUT',
    url: `${rootPath}/address/billing`,
  });

export const setAddresses = async (data: SetAddressesToCartRequestApiDto, options?: BaseOptions) =>
  apiRequest<void, SetAddressesToCartRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'PUT',
    url: `${rootPath}/addresses`,
  });

export const moveToWishlist = async (data: MoveCartSkuToWishlistRequestApiDto, options?: BaseOptions) =>
  apiRequest<void, MoveCartSkuToWishlistRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'PUT',
    url: `${rootPath}/wishlist`,
  });

const giftCardsPath = `${rootPath}/giftcards`;

export const applyGiftCard = async (code: string, options?: BaseOptions) =>
  apiRequest<void, void, void>({
    ...getBaseOptions(options),
    method: 'POST',
    url: `${giftCardsPath}/code/${code}`,
  });

export const removeGiftCard = async (code: string, options?: BaseOptions) =>
  apiRequest<void, void, void>({
    ...getBaseOptions(options),
    method: 'DELETE',
    url: `${giftCardsPath}/code/${code}`,
  });

export const addAssembled = async (data: AddAssembledToCartRequestApiDto, options?: BaseOptions) =>
  apiRequest<void, AddAssembledToCartRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'POST',
    url: `${rootPath}/assembledSku`,
  });

export interface GetOfferYProductOptions extends BaseOptions {
  populate?: Populate<ProductPopulate>;
}

export const getOfferYProducts = async <T>(offerId: string, options?: GetOfferYProductOptions) =>
  apiRequest<Array<ProductApiDto<T>>, void, ProductPopulate>({
    ...getBaseOptions(options),
    populate: options?.populate,
    url: `${rootPath}/offers/${offerId}/yProducts`,
  });
