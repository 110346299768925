import { OrdersTable } from '../../../OrdersTable';
import { useOrdersSearch } from '../../../useOrdersSearch';
import { AccountOrdersSubpageProps } from '../AccountOrdersSubpage';
import { AccountSubpage } from '../AccountSubpage';
import { Box } from './Box';

export const AccountOrdersSubpageA = ({ enableReceiverColumn }: AccountOrdersSubpageProps) => {
  const { searchResult } = useOrdersSearch({
    populate: {
      billingAddress: true,
      paymentMethod: true,
      shippingAddress: true,
      warehouseOrders: {
        carrierTracking: true,
        orderedSkus: {
          sku: {
            product: { attributes: true },
          },
        },
      },
    },
  });

  return (
    <AccountSubpage>
      <Box>
        <OrdersTable
          enableReceiverColumn={enableReceiverColumn}
          orders={searchResult?.items}
          pageNumber={searchResult?.pageNumber}
          pagesCount={searchResult?.pagesCount}
        />
      </Box>
    </AccountSubpage>
  );
};
