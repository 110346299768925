/* eslint-disable react/jsx-pascal-case */

import React from 'react';
import {
  A11y,
  Autoplay,
  Controller,
  EffectCards,
  EffectCoverflow,
  EffectCreative,
  EffectCube,
  EffectFade,
  EffectFlip,
  Mousewheel,
  Navigation,
  Pagination,
  Parallax,
  Scrollbar,
  Thumbs,
  Virtual,
} from 'swiper/modules';
import { Swiper as _Swiper, SwiperClass, SwiperProps as _SwiperProps, SwiperSlide as _SwiperSlide } from 'swiper/react';
import { NavigationOptions } from 'swiper/types/modules/navigation';
import { PaginationOptions } from 'swiper/types/modules/pagination';
import { ScrollbarOptions } from 'swiper/types/modules/scrollbar';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { Breakpoint } from './interfaces';
import { useSwiperContext } from './SwiperContext';
import { getBreakpointValue } from './util';

export type { SwiperClass } from 'swiper/react';
export { SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react';

export interface SwiperProps
  extends Omit<
    _SwiperProps,
    'modules' | 'children' | 'navigation' | 'breakpoints' | 'pagination' | 'scrollbar' | 'effect'
  > {
  breakpoints?: Partial<Record<Breakpoint, SwiperOptions>>;
  children?: React.ReactNode;
  effect?: 'slide' | 'fade' | 'cube' | 'coverflow' | 'flip' | 'creative' | 'cards';
  navigation?: (Omit<NavigationOptions, 'nextEl' | 'prevEl'> & { custom?: boolean }) | boolean;
  pagination?: (Omit<PaginationOptions, 'el'> & { custom?: boolean }) | boolean;
  scrollbar?: (Omit<ScrollbarOptions, 'el'> & { custom?: boolean }) | boolean;
  slides?: Array<React.ReactNode | ((slideData: SlideData) => React.ReactNode)>;
}

export interface SlideData {
  isActive: boolean;
  isNext: boolean;
  isPrev: boolean;
  isVisible: boolean;
}

// export interface NavigationOptions {
//   disabledClass?: string;
//   enabled?: boolean;
//   hiddenClass?: string;
//   hideOnClick?: boolean;
//   lockClass?: string;
//   navigationDisabledClass?: string;
// }

export const Swiper = ({
  autoplay,
  breakpoints,
  children,
  controller,
  effect,
  mousewheel = { enabled: true, forceToAxis: true, releaseOnEdges: true },
  navigation,
  onSlideChange,
  onSwiper,
  pagination,
  parallax,
  scrollbar,
  slides,
  thumbs,
  virtual,
  watchSlidesProgress,
  ...otherProps
}: SwiperProps) => {
  const { navigationNextElRef, navigationPrevElRef, paginationElRef, rerender, scrollbarElRef, setSwiper } =
    useSwiperContext();

  const handleSlideChange = React.useCallback(
    (_swiper: SwiperClass) => {
      rerender();
      onSlideChange && onSlideChange(_swiper);
    },
    [onSlideChange, rerender]
  );

  const handleSWiper = React.useCallback(
    (_swiper: SwiperClass) => {
      setSwiper(_swiper);
      onSwiper && onSwiper(_swiper);
    },
    [onSwiper, setSwiper]
  );

  const modules = React.useMemo(() => {
    const _modules = [];
    _modules.push(A11y);
    _modules.push(Controller);
    parallax && _modules.push(Parallax);
    navigation && _modules.push(Navigation);
    pagination && _modules.push(Pagination);
    scrollbar && _modules.push(Scrollbar);
    virtual && _modules.push(Virtual);
    autoplay && _modules.push(Autoplay);
    (thumbs || watchSlidesProgress) && _modules.push(Thumbs);
    mousewheel && _modules.push(Mousewheel);
    effect === 'fade' && _modules.push(EffectFade);
    effect === 'coverflow' && _modules.push(EffectCoverflow);
    effect === 'flip' && _modules.push(EffectFlip);
    effect === 'cube' && _modules.push(EffectCube);
    effect === 'cards' && _modules.push(EffectCards);
    effect === 'creative' && _modules.push(EffectCreative);
    return _modules;
  }, [autoplay, effect, mousewheel, navigation, pagination, parallax, scrollbar, thumbs, virtual, watchSlidesProgress]);

  return (
    <_Swiper
      {...otherProps}
      autoplay={autoplay}
      breakpoints={
        breakpoints
          ? Object.entries(breakpoints).reduce<Record<number, SwiperOptions>>((prev, [key, value]) => {
              prev[getBreakpointValue(key as Breakpoint)] = value;
              return prev;
            }, {})
          : undefined
      }
      controller={controller}
      effect={effect}
      modules={modules}
      mousewheel={mousewheel}
      navigation={
        navigation
          ? {
              ...(typeof navigation === 'object' ? navigation : undefined),
              nextEl: typeof navigation === 'object' && navigation.custom ? navigationNextElRef.current : undefined,
              prevEl: typeof navigation === 'object' && navigation.custom ? navigationPrevElRef.current : undefined,
            }
          : undefined
      }
      onSlideChange={handleSlideChange}
      onSwiper={handleSWiper}
      pagination={
        pagination
          ? {
              ...(typeof pagination === 'object' ? pagination : undefined),
              el: typeof pagination === 'object' && pagination.custom ? paginationElRef.current : undefined,
            }
          : undefined
      }
      parallax={parallax}
      scrollbar={
        scrollbar
          ? {
              ...(typeof scrollbar === 'object' ? scrollbar : undefined),
              el: typeof scrollbar === 'object' && scrollbar.custom ? scrollbarElRef.current : undefined,
            }
          : undefined
      }
      thumbs={
        thumbs
          ? {
              ...thumbs,
              swiper:
                typeof thumbs.swiper === 'object'
                  ? thumbs.swiper && !thumbs.swiper.destroyed // https://github.com/nolimits4web/swiper/issues/5630
                    ? thumbs.swiper
                    : undefined
                  : thumbs.swiper,
            }
          : undefined
      }
      virtual={virtual}
      watchSlidesProgress={watchSlidesProgress}
    >
      {slides
        ?.filter((slide) => slide !== undefined)
        .map((slide, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <_SwiperSlide key={index + 1} virtualIndex={index}>
            {(slideData) => (typeof slide === 'function' ? slide(slideData) : slide)}
          </_SwiperSlide>
        ))}
      {children}
    </_Swiper>
  );
};
