import { Resource } from '../i18n';

export const fr: Resource = {
  account: {
    coupons: {
      active: 'Actif',
      code: 'Code',
      description: 'Description',
      empty: 'Aucun bon de réduction',
      expired: 'Expiré',
      expiryDate: "Date d'expiration",
      numberOfUses: "Nombre d'utilisations",
      status: 'État',
    },
    create: 'Créez votre compte',
    creditCards: {
      addCreditcard: 'Ajouter une carte de crédit ou de débit',
      noCardRegistered: 'Aucune carte de crédit enregistrée',
    },
    edit: 'Modifier',
    emptyWishlist: 'Aucun produit ajouté à votre wishlist',
    info: {
      additionalInformation: {
        children: {
          birthdate: 'Date de naissance',
          name: 'Prénom',
          sex: 'Genre',
          surname: 'Nom',
          title: 'Enfant',
        },
        document: {
          authorityDescription: "Description de l'autorité",
          authorityType: "Type d'autorité",
          districtName: 'Nom de district',
          districtType: 'Type de district',
          documentNumber: 'Numéro de document',
          documentType: 'Type de document',
          releaseDate: 'Date de délivrance',
          title: 'Document',
        },
        other: 'Autre',
        title: 'Informations supplémentaires',
      },
      birthdate: 'Date de naissance',
      businessType: {
        label: "Type d'activité",
        options: {
          COMPANY: 'Entreprise',
          PROPRIETORSHIP: 'Entreprise individuelle / Autoentrepreneur',
        },
      },
      company: 'Dénomination sociale',
      editPassword: 'Modifier le mot de passe',
      email: 'Adresse e-mail',
      loginData: 'Données de connexion',
      name: 'Nom',
      pec: 'Adresse Lettres recommandées électroniques',
      personalAddresses: 'Adresses',
      personalData: 'Données personnelles',
      phone: 'Tél. portable',
      profile: {
        label: 'Profil',
        options: {
          BUSINESS: 'Affaires',
          PRIVATE: 'Particulier',
        },
      },
      secondaryPhone: 'Autre téléphone',
      sex: {
        options: {
          F: 'Femme',
          M: 'Homme',
          O: 'Non spécifié',
        },
        title: 'Genre',
      },
      surname: 'Nom',
      taxCode: 'Code fiscal',
      title: 'Données de facturation',
      uniqueCode: 'Code SDI',
      vatNumber: 'Numéro TVA',
    },
    insertCredentials: 'Si vous êtes enregistré, saisissez votre e-mail et votre mot de passe',
    login: 'Accéder',
    logout: 'Sortir',
    loyalty: {
      pointsBalance: 'votre solde de points',
      pointsTable: {
        awardsLabel: 'Récompenses',
        couponLabel: 'Code de réduction',
        footerText: "Utiliser le code de réduction au moment de l'achat",
        pointsLabel: 'Points',
        ptLabel: 'pt',
      },
      shopping: 'commencer le shopping',
    },
    newCustomer: 'Êtes-vous un nouveau client ?',
    orders: {
      details: {
        closeOrderDetails: 'Fermer',
        code: 'Code',
        color: 'Couleur :',
        date: 'Date',
        invoiceDownload: 'Télécharger la facture',
        invoiceNotReady: 'Facture pas encore prête',
        paymentAddress: 'Paiement',
        price: 'Prix',
        product: 'Article',
        quantity: 'Quantité :',
        receiverShipping: 'Destinataire',
        review: 'Récapitulatif',
        shippingAddress: 'Expédition',
        showOrderDetails: 'Voir le détail de la commande',
        size: 'Taille :',
        status: 'État',
        total: 'Total',
        totalAmount: 'total à payer : ',
        totalProducts: 'total des produits :',
        totalShipping: 'expédition :',
        trackingCode: "Suivi de l'expédition",
        unitPrice: 'Prix unitaire',
      },
      noOrders: "Il n'y a pas de commandes pour le moment",
      status: {
        arrived: 'Livré',
        cancelled: 'Annulé',
        preparing: 'En cours de préparation',
        readyToShipping: "Prêt pour l'expédition",
        shipping: 'Expédié',
        waitPayment: 'En attente de paiement',
        waitingAcceptance: "En cours d'acceptation",
      },
      trackOrdersState: "Suivez l'état de vos commandes",
    },
    passwordForgotten: 'Mot de passe oublié ?',
    register: 'Inscrivez-vous',
    sectionDisabled: 'Section désactivée',
    taxBenefits: {
      alerts: {
        danger: 'Les documents ne sont pas approuvés',
        success: 'Utilisateur autorisé',
        warning: "Les documents sont en cours d'approbation",
      },
    },
    userRegistered: 'Êtes-vous un client enregistré ?',
    welcomeMessage: 'Bonjour {{name}}, découvrez votre espace personnel',
    wishlist: 'Déplacer dans la wishlist',
  },
  alert: {
    CART_DIFFERENT_SKU_PRICE: '',
    CART_MINIMUM_COST: 'Montant minimum de panier non atteint',
    CART_MIN_CAR_SELLER_VIOLATED: '',
    CART_REGION_NOT_REACHABLE_EXTRA_FRESH: '',
    CART_SKU_NOT_SHIPPABLE_IN_COUNTRY_VIOLATED: 'Ce produit ne peut pas être vendu dans le pays sélectionné',
    GUEST_CHECKOUT_NOT_ALLOWED: '',
    INVOICE_MANDATORY_MISSING_DATA: 'Une facture a été demandée mais il manque des données de facturation',
    MISSING_BILLING_ADDRESS: "Il manque l'adresse de facturation",
    MISSING_EMAIL: "L'adresse e-mail n'a pas été saisie",
    MISSING_PAYMENT_METHOD: "Le mode de paiement n'a pas été sélectionné",
    MISSING_SHIPPING_ADDRESS: "Il manque l'adresse d'expédition",
    MISSING_SHIPPING_PROFILE: "Le profil d'expédition n'a pas été sélectionné",
    UNSOLICITED_INVOICE: "Attention, aucune délivrance de facture n'a été demandée pour cette commande",
    WAREHOUSEITEM_NOT_IN_STOCK: '',
    WAREHOUSESKU_NOT_AVAILABLE: 'Produit non disponible',
  },
  api: {
    error: {
      AddingSkuNotAllowed: "Il n'est pas possible d'acheter ce produit.",
      AddingSkuNotCompatible: '',
      AddingSkuNotSellable: "Le produit que vous souhaitez ajouter n'est pas disponible à la vente.",
      CouponAlreadyUsedException: 'Coupon déjà utilisé',
      CouponCartRangeException: "Le coupon n'est pas applicable à ce panier",
      CouponInvalidException: "Le code saisi ne correspond à aucun coupon et n'est pas applicable à ce panier",
      CouponLoggedGuestException: 'Pour utiliser le coupon, accédez ou enregistrez-vous sur le site',
      CustomerCannotSetFeedbackException: "Nous n'avons trouvé aucune commande d'achat contenant ce produit",
      CustomerEmailAlreadyExistException: 'Adresse e-mail déjà utilisée',
      CustomerEmailNotValid: "L'adresse e-mail saisie n'est pas valable",
      CustomerEmailNotVerifiedException:
        "Pour continuer, il faut confirmer l'enregistrement en utilisant le lien présent dans le courriel que vous avez reçu suite à l'enregistrement.",
      CustomerNotFoundException:
        "L'adresse saisie n'est pas présente dans nos systèmes, veuillez vérifier et réessayer.",
      CustomerUsernameAlreadyExistException: "Nom d'utilisateur déjà utilisé",
      DataReferenceException: 'Erreur interne',
      DepositPaymentCardNoRemainingCreditException: 'Aucun crédit résiduel',
      DepositPaymentCardNotFoundException: 'Code non reconnu',
      DepositPaymentCardNotPermittedOnCartException:
        "Il n'est pas possible d'utiliser la carte cadeau pour en acheter une autre",
      EJBException: 'Erreur interne',
      LoginException: "Nom d'utilisateur ou mot de passe non valables",
      LoginUserNotFoundException: 'Adresse e-mail non trouvée',
      SystemException: 'Erreur interne',
      TaxCodeCheckDigitException:
        "Le code fiscal saisi n'est pas valable, veuillez vérifier les données saisies et réessayer.",
      TaxCodeLenghtException:
        "Le code fiscal saisi n'est pas valable, veuillez vérifier les données saisies et réessayer.",
      VatNumberCheckDigitException:
        "Le numéro de TVA saisi n'est pas valable, veuillez vérifier les données saisies et réessayer.",
      VatNumberLenghtException:
        "Le numéro de TVA saisi n'est pas valable, veuillez vérifier les données saisies et réessayer.",
      title: 'Erreur',
      usernotlogged: 'session expirée',
    },
  },
  assembledProduct: {
    addToAssembly: "Ajouter à l'assemblage", // da revisionare
    addToCart: 'Ajouter au panier', // da revisionare
    completeTheAssembly: 'Assemblage complet', // da revisionare
    discountUpTo: "Jusqu'à {{percentage}}% de réduction", // da revisionare
    discountUpToMini: "Jusqu'à {{percentage}}%", // da revisionare
    freeShipping: 'Livraison gratuite', // da revisionare
    removeFromAssembly: "Supprimer l'article", // da revisionare
    summary: {
      actions: {
        addToCart: 'Acheter', // da revisionare
        next: 'Suivant', // da revisionare
        previous: 'Retour', // da revisionare
      },
      badges: {
        freeShipping: 'Livraison gratuite', // da revisionare
        totalDiscount: 'Vous sauvez {{value}}', // da revisionare
      },
      infoMessage: {
        addAtLeast: '',
        addAtLeast_one: 'Ajouter au moins un produit', // da revisionare
        addAtLeast_other: 'Ajouter au moins {{count}} produits', // da revisionare
        addMoreProducts: '',
        addMoreProducts_one: 'Ajouter un autre produit pour continuer', // da revisionare
        addMoreProducts_other: "Ajouter d'autres produits {{count}} pour continuer", // da revisionare
        completeAllStepToContinue: 'Pour continuer, vous devez compléter toutes les étapes', // da revisionare
        completeStepToContinue: "Pour continuer, vous devez compléter l'étape", // da revisionare
      },
      status: '{{produitAdded}}/{{maxProduit}} produits ajoutés', // da revisionare
      subtitle: 'Résumé de votre assemblée', // da revisionare
      title: 'Votre total', // da revisionare
      total: 'Total', // da revisionare
      yourAssembly: 'Votre assemblage', // da revisionare
    },
    totalPieces: '{{total}} pièces', // da revisionare
  },
  bankAccountDetails: {
    bankName: 'Banque',
    bicSwiftCode: 'BIC/SWIFT',
    ibanCode: 'IBAN',
    ownerName: 'Bénéficiaire',
  },
  cart: {
    badges: {
      discountValue: 'Vous économisez {{value}}', // da revisionare
      freeShipping: 'Livraison gratuite', // da revisionare
    },
    empty: {
      description: 'Commencez à chercher quelque chose',
      title: 'Votre panier est vide',
    },
    loyaltyPointsEarned: 'Votre panier peut vous faire gagner <strong>{{loyaltyPointsEarned}} points fidélité</strong>',
    modal: {
      shippingCountry: {
        alert: 'Attention ! Si vous changez de pays, votre panier sera vidé',
        title: "Souhaitez-vous changer de pays d'expédition ?",
      },
    },
    paymentMethods: {
      title: 'Modes de paiement disponibles',
    },
    productTile: {
      composedOf: 'Composé de',
      removeItem: "Éliminer l'article",
      sendToWishlist: 'Déplacer dans la wishlist',
    },
    proocedToCart: 'Aller au panier',
    quantity: 'Quantité',
    summary: {
      additional: {
        cashOnDelivery: 'Paiement à la livraison',
        title: 'Suppléments',
      },
      continueShopping: 'continuer le shopping',
      coupons: {
        removeCoupon: 'Enlever',
        title: 'Saisir un code de réduction (facultative)',
      },
      freeShipping: 'Gratuit',
      freeShippingFrom: 'Expéditions gratuites à partir de {{freeShippingFromAmount}}',
      giftCard: 'Cartes cadeau',
      proocedToCheckout: 'Continuer',
      shippingCountry: {
        edit: 'Changer',
        title: "Pays d'expédition",
      },
      shippingProfiles: {
        title: 'Expédition',
      },
      subtotal: 'Sous-total',
      title: 'Votre commande',
      toFreeShipping: "Il manque <strong>{{toFreeShippingAmount}}</strong> pour l'expédition gratuite",
      toFreeShippingComplete: "Vous avez droit à l'<b>expédition gratuite</b>",
      totalBillingCost: 'Total (TVA incluse)',
      totalCost: 'Total à payer',
      totalDiscount: 'Réductions',
      totalProductsCost: 'Coût produits',
    },
    title: 'Mon panier',
  },
  checkout: {
    backToCart: 'Retourner au panier',
    billing: {
      addNewAddress: 'Ajouter une nouvelle adresse',
    },
    cancel: 'Annuler',
    cannotStartPayment: "Oups, une erreur s'est produite dans votre panier, vérifiez les articles avant de continuer",
    edit: 'Modifier',
    invoice: {
      notRequested: "Vous n'avez pas demandé la facture",
      requested: 'Vous avez demandé la facture',
    },
    shipping: {
      addNewAddress: 'Ajouter une nouvelle adresse',
    },
    step: {
      billingAddress: {
        subTitle: 'Elle correspond à celle de livraison',
        title: 'Adresse de paiement',
      },
      email: {
        alert: {
          buttons: {
            label: '',
          },
          title: '',
        },
        insertCredentials: "Saisissez vos identifiants et effectuez l'accès",
        login: 'Accéder',
        orderAs: 'Vous êtes en train de commander en tant que',
        subTitle1: ' Passez votre commande plus rapidement.',
        subTitle2: 'Accédez avec votre compte',
        title: 'Adresse e-mail',
      },
      paymentMethod: {
        subTitle: 'Choisir le mode de paiement',
        title: 'Mode de paiement',
      },
      shippingAddress: {
        title: 'Adresse de livraison',
      },
    },
    summary: {
      additional: {
        cashOnDelivery: 'Paiement à la livraison',
        title: 'Suppléments',
      },
      cartItem: {
        edit: 'Modifier',
        title: "Vous êtes en train d'acheter",
      },
      coupon: {
        add: 'Ajouter',
        edit: 'Modifier',
        title: 'Coupons',
      },
      giftCard: {
        add: 'Ajouter',
        edit: 'Modifier',
        title: 'Carte cadeau',
      },
      shipping: {
        title: 'Expédition',
      },
      shippingCountry: {
        title: "Pays d'expédition",
      },
      subtotal: 'Sous-total',
      title: 'Votre commande',
      totalBillingCost: 'Total (TVA incluse)',
      totalCost: 'Total à payer',
      totalProductsCost: 'Total produits',
    },
    thankYou: {
      cashOnDelivery: {
        amountToPayOnDelivery: 'Montant à payer à la livraison',
      },
    },
  },
  contactUs: {
    customerOrder: {
      label: "Pour quelle commande avez-vous besoin d'aide ?",
      placeholder: 'Sélectionnez votre commande',
    },
    notSatisfied: {
      label: "Vous n'avez pas trouvé la réponse à votre question ?  Contactez-nous !",
    },
    satisfied: {
      label: 'Vous avez trouvé la réponse à votre question ?',
      no: 'non',
      yes: 'oui',
    },
    subject: {
      label: 'De quelle information avez-vous besoin ?',
      placeholder: 'Sélectionner une option',
    },
  },
  customerCare: {
    contact: 'Contactez-nous',
    help: 'Pouvons-nous vous aider ?',
    select: {
      label: 'De quelle information avez-vous besoin ?',
      optionLabel: 'Sélectionner une option',
    },
    service: "Consultez l'espace Service clients pour obtenir de l'aide rapidement et facilement",
  },
  error: {
    pageNotFound: "Oups ! Nous n'avons par trouvé la page que vous cherchiez",
  },
  fixedPagesName: {
    account: 'compte',
    cart: 'panier',
    'change-password': 'changement-mot de passe',
    checkout: 'déconnexion',
    error: 'erreur',
    'forgot-password': 'mot de passe-oublié',
    home: "page d'accueil",
    login: 'connexion',
    newsletter: "lettre d'information",
    'order-ko': 'commande-ko',
    'order-ok': 'commande-ok',
    register: 'enregistrer',
    search: 'chercher',
    sitemap: 'sitemap',
    template: 'tableau',
    test: 'test',
    'unsubscribe-newsletter': "désabonnement-lettre d'information",
    whois: 'qui est',
  },
  footer: {
    paymentsMethod: 'Modes de paiement',
  },
  form: {
    addToCartForm: {
      buttons: {
        availabilityEmailNotification: {
          addLong: { label: "M'alerter quand il est disponible" },
          addShort: { label: "M'alerter" },
          remove: { label: "Supprimer l'alerte" },
        },
        cancel: { label: 'Annuler' },
        reset: { label: 'Réinitialiser' },
        submit: { label: 'Ajouter au panier' },
        submitButNotAvailable: { label: 'Non disponible' },
      },
      fields: {
        giftCard: {
          message: {
            label: 'Message',
            placeholder: '',
          },
          receiverEmail: {
            label: 'Adresse e-mail du destinataire',
            placeholder: '',
          },
          receiverName: {
            label: 'Nom du destinataire',
            placeholder: '',
          },
          senderName: {
            label: 'Votre nom',
            placeholder: '',
          },
          value: {
            placeholder: 'Sélectionner une option',
          },
        },
        quantity: {
          label: 'Quantité',
          placeholder: "Sélectionner l'une des options",
        },
        skuAttributes: {
          Gift_card_valore: {
            placeholder: 'Sélectionner la valeur',
          },
        },
        skus: {
          label: 'UGS',
          placeholder: 'Sélectionner une ugs',
        },
      },
      misc: {
        productVariants: 'Format',
      },
      modal: {
        title: '',
      },
      outcome: {
        failure: {
          AddingSkuNotCompatible: {
            body: '',
            buttons: {
              no: { label: '' },
              yes: { label: '' },
            },
            title: '',
          },
        },
        success: {
          body: 'Produit ajouté au panier',
          buttons: {
            continueShopping: { label: 'Continuer le shopping' },
            goToCart: { label: 'Aller au panier' },
          },
          title: 'Produit ajouté au panier',
        },
      },
    },
    addressForm: {
      account: {
        defaultBilling: {
          label: 'Définir comme adresse de paiement prédéfinie',
        },
        defaultShipping: {
          label: "Définir comme adresse d'expédition prédéfinie",
        },
      },
      address: {
        addressLine1: {
          label: 'Adresse',
          placeholder: '',
        },
        addressLine2: {
          label: 'N° appartement, étage, a/s, etc...',
          placeholder: '',
        },
        addressLine3: {
          label: '',
          placeholder: '',
        },
        alternativePhone: {
          label: 'Autre n° de téléphone',
          placeholder: '',
        },
        city: {
          label: 'Ville',
          placeholder: '',
        },
        civicNumber: {
          label: 'N° de rue',
          placeholder: '',
        },
        country: {
          label: 'Pays',
          placeholder: '',
        },
        latitude: {
          label: 'Latitude',
          placeholder: '',
        },
        longitude: {
          label: 'Longitude',
          placeholder: '',
        },
        middleName: {
          label: 'Deuxième prénom',
          placeholder: '',
        },
        name: {
          label: 'Prénom',
          placeholder: '',
        },
        phone: {
          label: 'Téléphone',
          placeholder: '',
        },
        province: {
          label: 'Département',
        },
        region: {
          label: 'Région',
          placeholder: '',
        },
        shippingToAlert:
          "Vous demandez une expédition en {{locale}}, revenez en arrière pour changer de pays d'expédition.",
        surname: {
          label: 'Nom',
          placeholder: '',
        },
        zipCode: {
          label: 'CP',
        },
      },
      buttons: {
        cancel: {
          label: 'Annuler',
        },
        edit: {
          label: 'Modifier',
        },
        reset: {
          label: 'Réinitialiser',
        },
        submit: {
          label: 'Ajouter',
        },
      },
      copyFromBilling: {
        label: 'Utiliser la même adresse de paiement',
      },
      copyFromShipping: {
        label: "Utiliser la même adresse d'expédition",
      },
      copyToBilling: {
        label: 'Définir également comme adresse de paiement',
      },
      copyToShipping: {
        label: "Définir également comme adresse d'expédition",
      },
      editAddressTitle: "Modifier l'adresse",
      invoice: {
        data: {
          company: {
            label: 'Dénomination sociale',
            placeholder: '',
          },
          pec: {
            label: 'Adresse Lettres recommandées électroniques',
            placeholder: '',
          },
          taxCode: {
            label: 'Code fiscal',
            placeholder: '',
          },
          uniqueCode: {
            label: 'Code SDI',
            placeholder: '',
          },
          vatNumber: {
            label: 'Numéro TVA',
            placeholder: '',
          },
        },
        request: {
          label: 'Souhaitez-vous la facture ?',
        },
        type: {
          label: 'Type de client',
          options: {
            company: 'Entreprise',
            freelance: 'Entreprise individuelle / Autoentrepreneur',
            privatePerson: 'Particulier',
          },
        },
      },
      modal: {
        title: '',
      },
      newAddressTitle: 'Saisir une nouvelle adresse',
    },
    cartAddressFromAddressBookForm: {
      buttons: {
        cancel: { label: 'Annuler' },
        editAddress: { label: "Modifier l'adresse" },
        newAddress: { label: 'Ajouter une nouvelle adresse' },
        reset: { label: 'Réinitialiser' },
        submit: { label: 'Confirmer' },
      },
    },
    cartEmailForm: {
      buttons: {
        cancel: {
          label: 'Annuler',
        },
        reset: {
          label: 'Réinitialiser',
        },
        submit: {
          checkout: {
            label: "Modifier l'adresse e-mail",
          },
          whoIs: {
            label: 'Effectuer la commande',
          },
        },
      },
      fields: {
        email: {
          label: 'Adresse e-mail',
          placeholder: 'Adresse e-mail',
        },
        newsletterConsent: {
          label: '',
          radios: {
            false: '',
            true: '',
          },
        },
        privacy: {
          label: 'Je consens au traitement de mes données personnelles',
        },
      },
      handlers: {
        registeredEmail: {
          body: '',
          buttons: { cancel: { label: '' }, submit: { label: '' } },
          title: '',
        },
      },
      modal: {
        title: '',
      },
    },
    cartGenericCouponForm: {
      buttons: {
        cancel: {
          label: 'Annuler',
        },
        reset: {
          label: 'Réinitialiser',
        },
        submit: {
          label: 'Appliquer',
        },
      },
      fields: {
        code: {
          label: '',
          placeholder: 'Saisir le code',
        },
      },
      modal: {
        title: '',
      },
    },
    cartPaymentMethodsForm: {
      buttons: {
        cancel: {
          label: 'Annuler',
        },
        reset: {
          label: 'Réinitialiser',
        },
        submit: {
          label: 'Confirmer',
        },
      },
      modal: {
        title: '',
      },
    },
    cartPersonalCouponsForm: {
      misc: {
        noCoupon: '',
      },
      modal: {
        title: '',
      },
    },
    changePasswordByTokenForm: {
      buttons: {
        cancel: {
          label: 'Annuler',
        },
        reset: {
          label: 'Réinitialiser',
        },
        submit: {
          label: 'Envoyer la demande',
        },
      },
      fields: {
        confirmNewPassword: {
          label: 'Confirmer nouveau mot de passe',
          placeholder: 'Confirmer nouveau mot de passe',
        },
        newPassword: {
          label: 'Nouveau mot de passe',
          placeholder: 'Nouveau mot de passe',
        },
      },
      modal: {
        title: '',
      },
      outcome: {
        success: {
          body: 'La procédure de changement de mot de passe a bien été effectuée',
          title: 'Confirmer le changement de mot de passe',
        },
      },
    },
    changePasswordForm: {
      buttons: {
        cancel: {
          label: 'Annuler',
        },
        reset: {
          label: 'Réinitialiser',
        },
        submit: {
          label: 'Envoyer la demande',
        },
      },
      fields: {
        confirmNewPassword: {
          label: 'Confirmer nouveau mot de passe',
          placeholder: 'Confirmer nouveau mot de passe',
        },
        currentPassword: {
          label: 'Mot de passe actuel',
          placeholder: 'Mot de passe actuel',
        },
        newPassword: {
          label: 'Nouveau mot de passe',
          placeholder: 'Nouveau mot de passe',
        },
      },
      modal: {
        title: 'Changement de mot de passe',
      },
      outcome: {
        success: {
          body: 'La procédure de changement de mot de passe a bien été effectuée',
          title: 'Confirmer le changement de mot de passe',
        },
      },
    },
    consentsForm: {
      buttons: {
        cancel: {
          label: 'Annuler',
        },
        reset: {
          label: 'Réinitialiser',
        },
        submit: {
          label: 'Enregistrer',
        },
      },
      fields: {
        marketing: {
          label: 'Acceptation marketing',
          radios: {
            false: 'Non',
            true: 'Oui',
          },
        },
        newsletter: {
          label: "Acceptation lettre d'information",
          radios: {
            false: 'Non',
            true: 'Oui',
          },
        },
        profiling: {
          label: 'Acceptation profilage',
          radios: {
            false: 'Non',
            true: 'Oui',
          },
        },
      },
      modal: {
        title: '',
      },
    },
    customerCareForm: {
      buttons: {
        cancel: {
          label: 'Annuler',
        },
        reset: {
          label: 'Réinitialiser',
        },
        submit: {
          label: 'Envoyer',
        },
      },
      fields: {
        email: {
          label: 'Adresse e-mail',
          placeholder: '',
        },
        message: {
          label: 'Message',
          placeholder: '',
        },
        name: {
          label: 'Prénom',
          placeholder: '',
        },
        phoneNumber: {
          label: 'Téléphone',
          placeholder: '',
        },
        privacy: {
          label: "Je déclare avoir lu et accepté la notice d'information confidentialité",
          placeholder: '',
        },
        surname: {
          label: 'Nom',
          placeholder: '',
        },
      },
      modal: {
        title: '',
      },
      outcome: {
        success: {
          body: 'Message envoyé correctement',
          title: 'Envoi message',
        },
      },
    },
    customerForm: {
      birthdate: {
        errors: {
          minAge: 'Saisir une date de naissance valable',
          minAgeWithDate: '',
          minAgeWithDate_one: 'Vous devez avoir au moins {{count}} an',
          minAgeWithDate_other: 'Vous devez avoir au moins {{count}} ans',
        },
        label: 'Date de naissance',
      },
      businessType: {
        label: 'Comment est enregistrée votre activité',
        options: {
          company: 'Entreprise',
          proprietorship: 'Entreprise individuelle / Autoentrepreneur',
        },
        placeholder: 'Sélectionner une option',
      },
      buttons: {
        cancel: {
          label: 'Annuler',
        },
        reset: {
          label: 'Réinitialiser',
        },
        submit: {
          registrationLabel: 'Inscrivez-vous',
          updateLabel: 'Mettre à jour',
          updateRequiredFieldsLabel: 'Compléter le profil',
        },
      },
      children: {
        birthdate: {
          errors: {
            minAge: 'Saisir une date de naissance valable',
            minAgeWithDate: '',
            minAgeWithDate_one: 'Vous devez avoir au moins {{count}} an',
            minAgeWithDate_other: 'Vous devez avoir au moins {{count}} ans',
          },
          label: 'Date de naissance',
          placeholder: 'Date de naissance',
        },
        buttons: {
          add: {
            label: 'Ajouter un enfant',
          },
          remove: {
            label: 'Supprimer un enfant',
          },
        },
        formGroup: {
          label: 'Enfants (min : {{min}} max : {{max}} actuels : {{current}})',
        },
        limitDisclaimer: 'Vous avez atteint la limite maximale',
        name: {
          label: 'Prénom',
          placeholder: 'Prénom',
        },
        sex: {
          label: 'Sexe',
          options: {
            female: 'Féminin',
            male: 'Masculin',
            other: 'Je préfère ne pas le préciser',
          },
          placeholder: 'Sélectionner une option',
        },
        surname: {
          label: 'Nom',
          placeholder: 'Nom',
        },
      },
      company: {
        label: 'Dénomination sociale',
        placeholder: 'Dénomination sociale',
      },
      document: {
        authorityDescription: {
          label: "Description de l'autorité",
          placeholder: "Description de l'autorité",
        },
        authorityType: {
          label: "Type d'autorité",
          options: {
            consulate: 'Consulat',
            financeOffice: 'Bureau financier',
            foreignState: 'Pays étranger',
            motorization: 'Service des immatriculations',
            municipality: 'Commune',
            other: 'Autre',
            prefecture: 'Préfecture',
            unknown: 'Inconnu',
          },
          placeholder: "Type d'autorité",
        },
        districtName: {
          label: 'Nom de district',
          placeholder: 'Nom de district',
        },
        districtType: {
          label: 'Type de district',
          placeholder: 'Type de district',
        },
        documentNumber: {
          label: 'Numéro de document',
          placeholder: 'Numéro de document',
        },
        documentType: {
          label: 'Type de document',
          options: {
            drivingLicense: 'Permis de conduire',
            identityCard: "Carte d'identité",
            passport: 'Passeport',
          },
          placeholder: 'Type de document',
        },
        label: 'Document',
        releaseDate: {
          label: 'Date de délivrance',
          placeholder: 'Date de délivrance',
        },
      },
      email: {
        label: 'Adresse e-mail',
        placeholder: 'Adresse e-mail',
      },
      enableBusinessData: {
        formGroup: {
          label: 'Souhaitez-vous ajouter les données de votre activité ?',
        },
        options: {
          no: 'Non',
          yes: 'Oui',
        },
      },
      enableChildren: {
        formGroup: {
          label: 'Souhaitez-vous ajouter des enfants ?',
        },
        options: {
          no: 'Non',
          yes: 'Oui',
        },
      },
      enableDocument: {
        formGroup: {
          label: 'Souhaitez-vous ajouter un document ?',
        },
        options: {
          no: 'Non',
          yes: 'Oui',
        },
      },
      marketingConsent: {
        label: 'Marketing',
      },
      modal: {
        title: 'Données personnelles',
      },
      name: {
        label: 'Prénom',
        placeholder: 'Prénom',
      },
      newsletterConsent: {
        label: "Lettre d'information",
      },
      password: {
        label: 'Mot de passe',
        placeholder: 'Mot de passe',
      },
      pec: {
        label: 'Adresse Lettres recommandées électroniques',
        placeholder: 'Adresse Lettres recommandées électroniques',
      },
      phoneNumbers: {
        buttons: {
          add: {
            label: 'Ajouter un n° de téléphone',
          },
          remove: {
            label: 'Supprimer un n° de téléphone',
          },
        },
        label: 'Téléphone',
        placeholder: 'Téléphone',
      },
      privacyConsent: {
        label: 'Confidentialité',
      },
      profile: {
        label: 'Profil',
        options: {
          business: 'Affaires',
          private: 'Particulier',
        },
        placeholder: 'Sélectionner une option',
      },
      profilingConsent: {
        label: 'Profilage',
      },
      sex: {
        label: 'Sexe',
        options: {
          female: 'Femme',
          male: 'Homme',
          other: 'Je préfère ne pas le préciser',
        },
        placeholder: 'Sélectionner une option',
      },
      surname: {
        label: 'Nom',
        placeholder: 'Nom',
      },
      taxCode: {
        label: 'Identifiant Fiscal',
        placeholder: 'Identifiant Fiscal',
      },
      uniqueCode: {
        label: 'Code SDI',
        placeholder: 'Code SDI',
      },
      vatNumber: {
        label: 'Numéro TVA',
        placeholder: 'Numéro TVA',
      },
    },
    emailToCartForm: {
      buttons: {
        cancel: {
          label: 'Annuler',
        },
        reset: {
          label: 'Réinitialiser',
        },
        submit: {
          label: 'Effectuer la commande',
        },
      },
      modal: {
        title: '',
      },
    },
    exampleForm: {
      modal: {
        title: '',
      },
    },
    forgotPasswordForm: {
      buttons: {
        cancel: {
          label: 'Annuler',
        },
        reset: {
          label: 'Réinitialiser',
        },
        submit: {
          label: 'Envoyer la demande',
        },
      },
      fields: {
        email: {
          label: 'Adresse e-mail',
          placeholder: 'Adresse e-mail',
        },
      },
      modal: {
        title: '',
      },
      outcome: {
        success: {
          body: 'Opération terminée avec succès !\n\nNous vous avons envoyé un e-mail avec la procédure à suivre.',
          title: 'Demande de réinitialisation du mot de passe',
        },
      },
    },
    giftCardForm: {
      buttons: {
        balanceCheck: {
          label: 'Vérification du solde',
        },
        cancel: {
          label: 'Annuler',
        },
        reset: {
          label: 'Réinitialiser',
        },
        submit: {
          label: 'Utiliser',
        },
      },
      fields: {
        code: {
          label: 'Carte cadeau',
          placeholder: 'Saisir le numéro de carte cadeau',
        },
      },
    },
    localeForm: {
      buttons: {
        cancel: {
          label: 'Annuler',
        },
        reset: {
          label: 'Réinitialiser',
        },
        submit: {
          label: 'Aller',
        },
      },
      fields: {
        locale: {
          label: 'Langue',
        },
        shippingCountry: {
          label: 'Pays',
        },
      },
      modal: {
        title: '',
      },
    },
    loginForm: {
      buttons: {
        cancel: {
          label: 'Annuler',
        },
        reset: {
          label: 'Réinitialiser',
        },
        submit: {
          label: 'Connexion',
        },
      },
      fields: {
        password: {
          label: 'Mot de passe',
          placeholder: 'Mot de passe',
        },
        username: {
          label: 'Adresse e-mail',
          placeholder: 'Adresse e-mail',
        },
      },
      modal: {
        title: '',
      },
    },
    newsletterForm: {
      buttons: {
        cancel: {
          label: 'Annuler',
        },
        reset: {
          label: 'Réinitialiser',
        },
        submit: {
          label: 'Inscrivez-vous',
        },
      },
      fields: {
        email: {
          label: 'Adresse e-mail',
          placeholder: 'Saisissez votre adresse e-mail',
        },
        name: {
          label: 'Prénom',
          placeholder: 'Saisissez votre prénom',
        },
        newsletterConsent: {
          label: '',
        },
        surname: {
          label: 'Nom',
          placeholder: 'Saisissez votre nom',
        },
      },
      modal: {
        title: '',
      },
      outcome: {
        success: {
          body: "Félicitations ! Désormais, vous aussi faites partie des abonnés à la lettre d'information !",
          buttons: {
            ok: {
              label: 'Ok',
            },
          },
          title: "L'inscription à la lettre d'information a bien été effectuée ",
        },
      },
    },
    orderGuestForm: {
      buttons: {
        cancel: {
          label: 'Annuler',
        },
        reset: {
          label: 'Réinitialiser',
        },
        submit: {
          label: 'Chercher',
        },
      },
      fields: {
        email: {
          label: 'Adresse e-mail',
          placeholder: 'adresse e-mail',
        },
        orderCode: {
          label: 'Code commande',
          placeholder: 'code commande',
        },
      },
      modal: {
        title: '',
      },
    },
    restartPaymentForm: {
      buttons: {
        cancel: {
          label: 'Annuler',
        },
        reset: {
          label: 'Réinitialiser',
        },
        submit: {
          label: 'Payer maintenant',
        },
      },
      fields: {
        checkbox: {
          label: '',
        },
      },
      modal: {
        title: '',
      },
    },
    reviewForm: {
      buttons: {
        reset: {
          label: 'Réinitialiser',
        },
        submit: {
          label: 'Laisser un avis',
        },
      },
      fields: {
        message: {
          label: 'Ajouter un commentaire',
          placeholder: 'Écrivez votre avis',
        },
        rating: {
          label: 'Quel est votre degré de satisfaction pour <strong>{{productName}}</strong>?',
        },
      },
      modal: {
        title: 'Dites-nous ce que vous en pensez !',
      },
      outcome: {
        failure: {
          generic: {
            body: "Nous n'avons trouvé aucune commande d'achat contenant ce produit",
            title: 'Désolés',
          },
        },
        success: {
          body: 'Votre avis sera publié après validation par notre service clients.',
          title: "Merci pour votre retour d'information !",
        },
      },
    },
    shopSearchForm: {
      buttons: {
        cancel: {
          label: 'Annuler',
        },
        reset: {
          label: 'Réinitialiser',
        },
        submit: {
          label: 'Chercher',
        },
      },
    },
    simpleSearchForm: {
      fields: {
        text: {
          label: 'Chercher',
          placeholder: 'Chercher',
        },
      },
    },
    startPaymentForm: {
      buttons: {
        cancel: {
          label: 'Annuler',
        },
        reset: {
          label: 'Réinitialiser',
        },
        submit: {
          label: 'Acheter',
        },
      },
      fields: {
        checkbox: {
          label: '',
        },
      },
      modal: {
        title: '',
      },
    },
    taxBenefitsForm: {
      buttons: {
        browseFile: 'Joindre un document',
        cancel: 'Annuler',
        reset: {
          label: 'Réinitialiser',
        },
        submit: 'Enregistrer la documentation',
      },
      documentValidation: {
        approved: 'Document validé.',
        awating: "Document en cours d'approbation.",
        rejected: 'Document refusé. Charger un nouveau document valable.',
      },
      documentation: {
        label: 'Télécharger la documentation',
      },
      taxCode: {
        label: 'Code fiscal',
        placeholder: 'Code fiscal',
      },
    },
    updateCustomerRequiredFieldsForm: {
      modal: {
        title: '',
      },
    },
  },
  header: {
    cartSummary: '',
    cartSummary_one: 'Récapitulatif de commande ({{count}} article)',
    cartSummary_other: 'Récapitulatif de commande ({{count}} articles)',
    freeShippingFrom: 'Expéditions gratuites en {{locale}} depuis {{from}}',
    sendTo: 'Pays : {{country}} | {{locale}}',
  },
  i18nTest: 'Ceci est un test d b2x-react',
  misc: {
    account: 'Compte',
    addAddress: 'Ajouter une adresse',
    addToCart: 'Ajouter',
    amount: 'Montant',
    availabilityEmailNotification: "M'alerter",
    availabilityNotification: 'Nous vous alerterons quand il sera disponible',
    back: 'Retour',
    backToHome: "Revenir à la page d'accueil",
    bestPrice: {
      info: 'infos',
      modal: {
        lowestPrice: 'Prix le plus bas au cours des 30 derniers jours',
        priceToday: "Combien coûte cet article aujourd'hui",
        title: "Historique du prix de l'article",
      },
      priceLabel: 'Dernier prix le plus bas :',
    },
    categories: 'Catégories',
    changeCountryMessage: 'Attention ! Si vous changez de pays, votre panier sera vidé',
    checkout: 'Caisse',
    code: 'Code',
    customerForm: {
      registrationSuccessful: {
        modal: {
          body: 'Merci de votre inscription.', //da revisionare
          title: 'Inscription effectuée', //da revisionare
        },
      },
    },
    customerService: 'Assistance clients',
    directions: 'Indications',
    discoverMore: 'en savoir plus',
    discoverOurCollection: 'Découvrez la collection',
    discoverOurProducts: 'Découvrez nos produits',
    doubleOptIn: {
      body: 'Votre adresse e-mail a bien été validé, effectuez la connexion pour commencer à naviguer.',
      title: 'Compte vérifié',
    },
    edit: '',
    fileInput: {
      label: 'Choisir un fichier',
      placeholder: 'Aucun fichier sélectionné',
      uploading: 'chargement...',
    },
    filter: 'Filtrer',
    filterAndOrderBy: 'Filtrer et ranger par',
    filterBy: 'Filtrer par',
    filterYourSearchResults: 'Filtrez les résultats de votre recherche',
    followUs: 'Suivez-nous',
    fromPrice: 'de {{value}}',
    giftCard: {
      balance: {
        activation: 'Activation',
        expiration: 'Expiration',
        title: 'Carte cadeau détail',
        value: 'Solde',
      },
      label: 'Carte cadeau',
      message: 'Visualiser le message',
      receiver: {
        label: 'Pour :',
        mail: 'Adresse e-mail :',
      },
      sender: {
        label: 'De :',
        mail: 'Adresse e-mail :',
      },
      value: 'Valeur :',
    },
    giftProduct: 'Cadeau',
    gratis: 'Gratuit',
    invalid_form_values: 'Vérifier tous les champs avant de continuer',
    invoice: {
      company: 'Dénomination sociale',
      pec: 'Adresse Lettres recommandées électroniques',
      taxCode: 'Code fiscal',
      uniqueCode: 'Code SDI',
      vatNumber: 'Numéro TVA',
    },
    locale: {
      box: {
        title: {
          country: 'Sélectionner le pays et la langue',
          language: 'Sélection de la langue',
        },
      },
    },
    loginRegister: "S'identifier/S'inscrire",
    myOrders: 'Mes commandes',
    newProductSticker: 'Nouveau',
    newsletterTitle: "Lettre d'information",
    or: '',
    orderBy: 'Ranger par',
    orderCode: 'Code commande',
    passwordInput: {
      hide: 'cacher',
      show: 'montrer',
    },
    payPalCheckout: {
      advanced: {
        privacyNotice: '',
      },
      capture: {
        errorModal: {
          body: '',
          title: '',
        },
      },
      form: {
        buttons: {
          cancel: '',
          submit: '',
        },
        fields: {
          cvv: {
            placeholder: '',
          },
          expiry: {
            placeholder: '',
          },
          name: {
            placeholder: '',
          },
          number: {
            placeholder: '',
          },
        },
        state: {
          invalid: '',
        },
      },
    },
    payments: 'Paiements',
    preferences: 'Vous pourriez être intéressé par',
    price: '{{value}}',
    pricePerShape: '{{price}} au {{shape}}',
    pricePerUnit: '{{price}} au {{measurementUnit}}',
    productNotAvailable: 'Non disponible',
    productsPicker: {
      progress: '',
      progress_one: '',
      progress_other: '',
      select: '',
      selected: '',
    },
    readMore: 'Lire autre',
    register: 'Enregistrez-vous maintenant',
    relatedProducts: 'Vous pourriez également être intéressé par',
    removeFilters: 'Supprimer tous',
    searchItemResult: '',
    searchItemResult_one: '{{count}} résultat',
    searchItemResult_other: '{{count}} résultats',
    shipment: 'Expéditions et retours',
    showLess: 'Fermer',
    showMore: 'Lire tout',
    sizeGuide: 'guide des tailles',
    storeName: '',
    time: {
      days: '',
      days_one: 'Jour',
      days_other: 'Jours',
      hours: '',
      hours_one: 'Heure',
      hours_other: 'Heures',
      minutes: '',
      minutes_one: 'Minute',
      minutes_other: 'Minutes',
      seconds: '',
      seconds_one: 'Seconde',
      seconds_other: 'Secondes',
    },
    unsubscribeNewsletterByToken: {
      pending: "Désabonnement à la lettre d'information en cours...",
      success: "Le désabonnement à la lettre d'information a bien été effectué !",
      title: "Lettre d'information",
    },
    vat: '(TVA à {{value}} %)',
    viewAll: 'Voir tous',
    welcomeMessage: 'Bonjour {{name}}',
    wishlist: 'Liste de souhaits',
    youSearchedFor: 'Vous avez cherché :',
  },
  modal: {
    geolocation: {
      content:
        "{{storeName}} va vous demander d'accéder à votre localisation pour une meilleure expérience de service.",
      title: 'Géolocalisation {{storeName}}',
    },
  },
  paymentMethod: {
    ALIAS: "Paiement par l'intermédiaire d'un Alias",
    ANTICIPATO_BONIFICO: 'Anticipé par Virement Bancaire',
    ANTICIPATO_CC: 'Anticipé par Carte de Crédit',
    B2X_DIRECT: '',
    BANCOMAT_PAY: 'Paiement par carte de débit',
    BANK_TRANSFER: 'Virement Bancaire',
    BNL: "Carte de crédit par l'intermédiaire de BNL",
    CALL_CENTER: "Centre d'appel",
    CASH: 'ESPÈCES',
    CASH_ON_DELIVERY: 'Paiement à la livraison',
    CMCIC: '',
    EASYPAY_CC: '',
    EASYPAY_MB: '',
    EASYPAY_MBWAY: '',
    ECPAY: '',
    FATTURA_30: 'Facture payable sous 30 jours',
    FATTURA_60: 'Facture payable sous 60 jours',
    FIDELITY_CARD: '',
    GESTPAY: '',
    GESTPAY_MYBANK: '',
    GIFT_CARD: 'Carte cadeau',
    KEYCLIENT: 'Carte de crédit',
    KLARNA: '',
    MULTIBANCO: '',
    MYBANK: '',
    NOT_NECESSARY_PAYMENT: 'Paiement non nécessaire',
    NOT_SETTED_PAYMENT: 'Paiement non configuré',
    PAYPAL: 'PayPal',
    PAYPAL_V2: '',
    PAY_ON_PHYSICAL_SHOP: '',
    POS: 'Carte de crédit via terminal de paiement sur place',
    POSTE_BPIOL: 'Poste BPIOL',
    POSTE_BPOL: 'Poste BPOL',
    POSTE_CC: "Carte de crédit par l'intermédiaire de la Poste",
    POSTE_PAY: 'PostePay',
    RIBA60: 'RIB 60',
    RIBA_30: 'RIB 30',
    RIMESSA_DIRETTA: 'Paiement Comptant',
    SATISPAY: 'Satispay',
    SCALAPAY: 'Paiement par Scalapay',
    SETEFI: 'Carte de crédit',
    SETEFI_MASTERPASS: 'Masterpass',
    THIRD_PARTIES: 'PostePay',
    WANNME_BT: '',
    WANNME_CC: '',
    WANNME_PAY7: '',
    WANNME_RECURRENT: '',
    WORLDLINE_EASY_CHECKOUT: 'Carte de débit/crédit',
  },
  product: {
    caloriesPer100g: 'Calories pour 100 g',
    code: 'Code de produit',
    cookingMinutes: 'Minutes - temps de cuisson',
    description: 'Description',
    details: 'Détails du produit',
    dryingTime: 'Heures de séchage certifiées',
    features: 'Caractéristiques',
    freeShippingFrom: 'Expéditions gratuites à partir de {{freeShippingFromAmount}}',
    minutesOfCookingAlDente: 'Minutes - al dente',
    promo: 'Promo',
    relatedProduct: {
      title: 'Vous pourriez également être intéressé par',
    },
    selectSize: 'Sélectionner une taille',
    share: 'Partager',
    size: 'Format',
    sizeGuide: 'Guide des tailles',
    temporary: {
      shipping: 'Livraison rapide et retour facile dans les 30 jours.',
      sizeGuide: 'Vous ne connaissez pas votre taille ?',
    },
    usageAndMaintenance: 'Utilisation et entretien',
  },
  reviews: {
    buttons: {
      reviewForFirst: 'Soyez le premier à donner un avis sur ce produit',
      showMore: "Montrer d'autres",
      signIn: 'Accéder',
      signUp: 'Inscrivez-vous',
      writeReview: 'Laisser un avis',
    },
    messages: {
      customer: 'Partagez votre expérience sur ce produit.',
      guest: 'Accédez ou enregistrez-vous pour laisser un avis.',
      withReviwes: 'Avez-vous déjà essayé ce produit ?',
      withoutReviews:
        'Avez-vous déjà essayé ce produit ? Soyez le premier à partager votre expérience et à nous dire ce que vous en pensez !',
    },
    misc: {
      emptyList: "Il n'y a pas d'avis",
      filterBy: 'Filtrer par',
      review: '',
      review_one: '{{count}} Avis',
      review_other: '{{count}} Avis',
      star: '',
      star_one: 'Étoile',
      star_other: 'Étoiles',
      writeReview: 'Laisser un avis',
    },
    review: {
      notVerified: 'Avis non vérifié',
      purchasedOn: 'Acheté : {{date}}',
      reviewedOn: 'Commenté : {{date}}',
    },
    titles: {
      withReviwes: 'Tous les avis',
      withoutReviews: 'Laisser un avis',
    },
  },
  sharingModal: {
    methods: {
      clipboard: {
        label: 'Copier le lien',
        success: 'Lien copié dans le presse-papiers',
      },
      email: {
        label: 'Adresse e-mail',
      },
      facebook: {
        label: 'Facebook',
      },
      messenger: {
        label: 'Messenger',
      },
      whatsApp: {
        label: 'WhatsApp',
      },
      x: {
        label: 'X',
      },
    },
    title: 'Partager',
  },
  sortingOption: {
    default: 'Pertinence',
    id: {
      asc: '',
      desc: '',
    },
    name: {
      asc: '',
      desc: '',
    },
    price: {
      asc: 'Prix bas',
      desc: 'Pris élevé',
    },
    priority: {
      asc: '',
      desc: '',
    },
  },
  storeLocator: {
    filterPanel: {
      selectProduct: 'Sélectionner le produit',
    },
    listingPanel: {
      loading: 'chargement...',
      shopsCount: '',
      shopsCount_one: '{{count}} Point de vente',
      shopsCount_other: '{{count}} Points de vente',
    },
    modal: {
      desktopPhone: {
        content: "Pour plus d'informations sur {{name}}, contactez le numéro {{phoneNumber}}",
        title: 'Contacter le point de vente',
      },
      gallery: {
        title: 'Photo du point de vente',
      },
      geolocationError: {
        content: 'Géolocalisation refusée',
        title: 'Impossible de centrer la carte, géolocalisation refusée',
      },
    },
    shopDetails: {
      closed: 'Fermé',
      cta: {
        call: 'Appeler',
        directions: "Plan d'accès",
      },
      description: 'Description',
      openingDays: {
        FRIDAY: 'Vendredi',
        MONDAY: 'Lundi',
        SATURDAY: 'Samedi',
        SUNDAY: 'Dimanche',
        THURSDAY: 'Jeudi',
        TUESDAY: 'Mardi',
        WEDNESDAY: 'Mercredi',
      },
      socialNetwork: 'Réseau social',
    },
    shopTile: {
      CLOSED: 'Fermé',
      OPEN: 'Ouvert',
      closesAtDayTime: 'Fermeture {{day}} à {{time}}',
      closesAtTime: 'Fermeture à {{time}}',
      copyLink: 'Copier le lien',
      linkCopied: 'Lien copié dans le presse-papiers',
      opensAtDayTime: 'Ouverture {{day}} à {{time}}',
      opensAtTime: 'Ouverture à {{time}}',
      share: 'Partager',
    },
  },
  topSuggestionsBox: {
    categories: 'Catégories',
    popularSearches: 'Recherches fréquentes',
    recentSearches: 'Recherches récentes',
    showAllProducts: 'Découvrez tous les produits',
  },
  updateCustomerRequiredFieldsPage: {
    subtitle: 'Renseignez vos données et commencez à acheter dans la boutique',
    title: 'Bonjour',
    titleWithName: 'Bonjour {{name}}',
  },
  wishlist: {
    modal: {
      availabilityNotificationDisabled: 'Vous ne serez plus alerté quand le produit sera à nouveau disponible.',
      availabilityNotificationEnabled: 'Vous serez alerté dès que le produit sera à nouveau disponible !',
      buttons: {
        continueShopping: {
          label: 'Continuer le shopping',
        },
        goToWishlist: {
          label: 'Aller à la wishlist',
        },
      },
      productAdded: 'Le produit sélectionné a été ajouté à la wishlist.',
      productRemoved: 'Le produit sélectionné a été supprimé de la wishlist.',
      title: 'Mise à jour de la wishlist',
    },
  },
  yup: {
    array: {
      length: 'Doit contenir {{length}} éléments',
      max: 'Doit contenir un nombre inférieur ou égal à {{max}} éléments',
      min: 'Doit contenir au moins {{min}} éléments',
    },
    boolean: {
      isValue: 'Doit être {{value}}',
    },
    custom: {
      passwordConfirmationNotMatch: "La confirmation du mot de passe n'est pas correcte",
    },
    date: {
      max: 'Doit être antérieur à {{max}}',
      min: 'Doit être postérieur à {{min}}',
    },
    mixed: {
      default: 'Champ non valable',
      defined: 'Champ non défini',
      notOneOf: "Ne doit pas être l'une des valeurs suivantes : {{values}}",
      notType: 'Doit être de type `{{type}}`, mais la valeur finale était : {{value}}',
      oneOf: "Doit être l'une des valeurs suivantes : {{values}}",
      required: 'Champ obligatoire',
    },
    number: {
      integer: 'Doit être un nombre entier',
      lessThan: 'Doit être inférieur à {{less}}',
      max: 'Doit être inférieur ou égal à {{max}}',
      min: 'Doit être supérieur ou égal à {{min}}',
      moreThan: 'Doit être supérieur à {{more}}',
      negative: 'Doit être un nombre négatif',
      positive: 'Doit être un nombre positif',
    },
    object: {
      noUnknown: 'Le champ a des clés non spécifiées : {{unknown}}',
    },
    string: {
      email: 'Format e-mail non valable',
      length: 'Doit avoir exactement {{length}} caractères',
      lowercase: 'Doit être une chaîne en minuscules',
      matches: 'Doit coïncider avec : "{{regex}}"',
      max: 'Doit contenir {{max}} caractères maximum',
      min: 'Doit contenir {{min}} caractères minimum',
      trim: "Ne doit pas contenir d'espaces",
      uppercase: 'Doit être une chaîne en majuscules',
      url: 'Doit être une URL valable',
      uuid: 'Doit être un UUID valable',
    },
  },
};
