import { OrderApiDto } from '@b2x/storefront-api-js-client/src';

import { OrdersTableA } from './OrdersTableA';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface OrdersTableProps {
  defaultExpandedRows?: Array<number>;
  enableReceiverColumn?: boolean;
  orders?: Array<OrderApiDto>;
  pageNumber?: number;
  pagesCount?: number;
}

export type OrdersTableVariants = 'A';

const OrdersTableController = (props: PropsWithCustomComponent<OrdersTableProps>) => (
  <VariantsController<OrdersTableProps, OrdersTableVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: OrdersTableA },
      name: 'OrdersTable',
    }}
  />
);
export { OrdersTableController as OrdersTable };
