import classNames from 'classnames';

import { ImageProps } from './Image';
import { renderUI } from './util';

export interface ImageAsBackgroundProps extends Omit<ImageProps, 'aspectRatio' | 'height' | 'width' | 'fluid'> {
  backgroundPosition?: 'top' | 'center' | 'bottom' | 'left' | 'right' | 'initial';
  backgroundSize?: 'auto' | 'cover' | 'contain' | 'initial';
}

export const ImageAsBackground = ({
  backgroundPosition,
  backgroundSize = 'cover',
  className,
  src,
}: ImageAsBackgroundProps) => {
  const srcSet: Array<{ className: string; src: string }> = [];

  if (src === undefined) {
    // non faccio nulla
  } else if (typeof src === 'string') {
    srcSet.push({
      className: 'd-block',
      src: src,
    });
  } else {
    const srcXs = src.xs;
    const srcSm = src.sm ? src.sm : srcXs;
    const srcMd = src.md ? src.md : srcSm;
    const srcLg = src.lg ? src.lg : srcMd;
    const srcXl = src.xl ? src.xl : srcLg;
    const srcXxl = src.xxl ? src.xxl : srcXl;

    srcSet.push({
      className: 'd-block d-sm-none',
      src: srcXs,
    });
    srcSet.push({
      className: 'd-none d-sm-block d-md-none',
      src: srcSm,
    });
    srcSet.push({
      className: 'd-none d-md-block d-lg-none',
      src: srcMd,
    });
    srcSet.push({
      className: 'd-none d-lg-block d-xl-none',
      src: srcLg,
    });
    srcSet.push({
      className: 'd-none d-xl-block d-xxl-none',
      src: srcXl,
    });
    srcSet.push({
      className: 'd-none d-xxl-block',
      src: srcXxl,
    });
  }

  // Note b2x: Manca da gestire il 2x e il format image

  return renderUI({
    bs5: (
      <div className={classNames('h-100', className)}>
        {srcSet.map((imageBackground) => (
          <div
            className={`h-100 ${imageBackground.className}`}
            key={imageBackground.className}
            style={{
              background: `url(${imageBackground.src}) no-repeat scroll center center / ${backgroundSize} transparent`,
              backgroundPosition: backgroundPosition,
            }}
          ></div>
        ))}
      </div>
    ),
  });
};
