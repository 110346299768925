import { ProductApiDto } from '@b2x/storefront-api-js-client/src/dto';
import React from 'react';

import { createContext } from './util';

export interface ListingContextInterface {
  getProductIndex(productId: string): number;
  getSkuIndex(skuId: string): number;
  // id: string;
  name: string;
}

export const [ListingContextProvider, useListingContext, useListingContextStrict] =
  createContext<ListingContextInterface>('ListingContext');

interface UseListingContextInitializerProps {
  name: string;
  products: Array<ProductApiDto>;
}

const useListingContextInitializer = ({ name, products }: UseListingContextInitializerProps) => {
  const productsMap = React.useMemo<Record<string, number>>(
    () => Object.fromEntries(products.map((product, index) => [product.id, index])),
    [products]
  );
  const getProductIndex = React.useCallback((productId: string) => productsMap[productId], [productsMap]);

  // Da usare solo se si è sicuri di avere prodotti monosku
  const skusMap = React.useMemo<Record<string, number>>(
    () => Object.fromEntries(products.map((product, index) => [product.skus?.at(0), index])),
    [products]
  );
  const getSkuIndex = React.useCallback((skuId: string) => skusMap[skuId], [skusMap]);

  const listingContext: ListingContextInterface = React.useMemo(
    () => ({
      getProductIndex,
      getSkuIndex,
      // id,
      name,
    }),
    [getProductIndex, getSkuIndex, name]
  );

  return {
    ListingContextProvider,
    listingContext,
  };
};

export interface ListingContextProps extends UseListingContextInitializerProps {
  children: React.ReactNode | ((listingContext: ListingContextInterface) => React.ReactNode);
}

export const ListingContext = ({ children, ...otherProps }: ListingContextProps) => {
  const listingContextInitializer = useListingContextInitializer(otherProps);
  return (
    <listingContextInitializer.ListingContextProvider value={listingContextInitializer.listingContext}>
      {typeof children === 'function' ? children(listingContextInitializer.listingContext) : children}
    </listingContextInitializer.ListingContextProvider>
  );
};
