import {
  CreateReviewRequestApiDto,
  OrderingApiType,
  ProductApiDto,
  SearchProductOrderApiType,
  SearchProductsApiDto,
  SkuApiDto,
} from './dto';
import { PagePopulate } from './pages';
import { apiRequest, BaseOptions, getBaseOptions, Populate } from './utils/util';

export interface ProductPopulate {
  alternativeImages?: boolean;
  assembledComponents?: Populate<AssembledComponentPopulate>;
  associatedProducts?: Populate<ProductPopulate>;
  attributeGroups?: boolean;
  attributes?: boolean;
  brand?: boolean;
  breadcrumbs?: Populate<PagePopulate>;
  bundleProducts?: Populate<ProductPopulate>;
  content?: boolean;
  offers?: boolean;
  pages?: Populate<PagePopulate>;
  priceRange?: boolean;
  reviews?: boolean;
  skus?: Populate<SkuPopulate>;
  summaryReviews?: boolean;
  variantProducts?: Populate<ProductPopulate>;
}

export interface SkuPopulate {
  alternativeImages?: boolean;
  attributes?: boolean;
  closestExpiringDate?: boolean;
  measurement?: boolean;
  price?: boolean;
  product?: Populate<ProductPopulate>;
}

export interface AssembledComponentPopulate {
  componentSkus?: Populate<AssembledComponentSkuPopulate>;
}

export interface AssembledComponentSkuPopulate {
  price?: boolean;
  sku?: Populate<SkuPopulate>;
}

const rootPath = '/v2/products';

// export interface PopulateSelector {
//   code?: string;
//   id?: string;
//   type?: string;
// }

export interface FilterPopulate {
  selector?: boolean;
}

export interface SearchProductsPopulate {
  filters?: Populate<FilterPopulate>;
  items?: Populate<ProductPopulate>;
}

export interface SearchProductsOptions extends BaseOptions {
  brands?: Array<string>;
  categories?: Array<string>;
  facets?: Array<string>;
  filters?: Array<string>;
  fixed?: Array<string>;
  friendlyUrl?: string;
  internals?: Array<string>;
  limit?: number;
  offset?: number;
  orderBy?: SearchProductOrderApiType;
  orderingType?: OrderingApiType;
  populate?: SearchProductsPopulate;
  products?: Array<string>;
  simpleSearch?: string;
  warehouses?: Array<string>;
}

export const search = async (queryKey?: Array<unknown>, options?: SearchProductsOptions) =>
  apiRequest<SearchProductsApiDto, void, SearchProductsPopulate>({
    ...getBaseOptions(options),
    params: {
      brands: options?.brands,
      categories: options?.categories,
      facets: options?.facets,
      filters: options?.filters,
      fixed: options?.fixed,
      friendlyUrl: options?.friendlyUrl,
      internals: options?.internals,
      limit: options?.limit,
      offset: options?.offset,
      orderBy: options?.orderBy,
      orderingType: options?.orderingType,
      products: options?.products,
      simpleSearch: options?.simpleSearch,
      warehouses: options?.warehouses,
    },
    populate: options?.populate,
    queryKey: queryKey,
    staleTime: Infinity,
    url: `${rootPath}`,
  });

export interface GetProductOptions extends BaseOptions {
  populate?: Populate<ProductPopulate>;
}

export const get = async <T>(id: string, options?: GetProductOptions) =>
  apiRequest<ProductApiDto<T>, void, ProductPopulate>({
    ...getBaseOptions(options),
    populate: options?.populate,
    staleTime: Infinity,
    url: `${rootPath}/${id}`,
  });

export const getByPath = async <T>(path: string, options?: GetProductOptions) =>
  apiRequest<ProductApiDto<T>, void, ProductPopulate>({
    ...getBaseOptions(options),
    populate: options?.populate,
    staleTime: Infinity,
    url: `${rootPath}/path/${path}`,
  });

export interface GetSkuOptions extends BaseOptions {
  populate?: Populate<SkuPopulate>;
}

export const getSku = async (id: string, options?: GetSkuOptions) =>
  apiRequest<SkuApiDto, void, SkuPopulate>({
    ...getBaseOptions(options),
    populate: options?.populate,
    staleTime: Infinity,
    url: `${rootPath}/sku/${id}`,
  });

export const getUrlInAnotherLocaleByPath = async (path: string, locale: string, options?: BaseOptions) =>
  apiRequest<string, void, void>({
    ...getBaseOptions(options),
    staleTime: Infinity,
    url: `${rootPath}/locale/${locale}/path/${path}`,
  });

export const getUrlsInAllLocalesByPath = async (path: string, options?: BaseOptions) =>
  apiRequest<Record<string, string>, void, void>({
    ...getBaseOptions(options),
    staleTime: Infinity,
    url: `${rootPath}/locale/all/path/${path}`,
  });

export const writeReview = async (id: string, data: CreateReviewRequestApiDto, options?: BaseOptions) =>
  apiRequest<void, CreateReviewRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'POST',
    url: `${rootPath}/${id}/reviews`,
  });
