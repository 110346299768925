import React from 'react';

import { useWindowSize } from './useWindowSize';
import { createContext } from './util';

// Intefaccia contiene tutte le informazioni da passare ai consumer del contesto
export interface EqualHeightContextInterface {
  updateChecker: unknown;
  updateTrigger(): void;
}

export const [EqualHeightContextProvider, useEqualHeightContext, useEqualHeightContextStrict] =
  createContext<EqualHeightContextInterface>('EqualHeightContext');

// Proprietà necessarie per creare il contesto
interface UseEqualHeightContextInitializerProps {
  // updateAfterTotMillisecondsFromFirstRender?: number;
  updateEveryTotMilliseconds?: number;
}

// Funzione per inizializzare il contesto
const useEqualHeightContextInitializer = ({
  // updateAfterTotMillisecondsFromFirstRender = 200, // se non lo passo, lo faccio ogni ora.
  updateEveryTotMilliseconds = 60 + 60 * 1000,
}: UseEqualHeightContextInitializerProps) => {
  const [counter, setCounter] = React.useState<number>(0);
  const windowSize = useWindowSize();

  const updateTrigger = React.useCallback(() => {
    setCounter((prevState) => prevState + 1);
  }, []);

  // // Lo aggiorno dopo tot millisecondi dal primo render
  // React.useEffect(() => {
  //   window.setTimeout(() => {
  //     updateTrigger();
  //   }, updateAfterTotMillisecondsFromFirstRender);
  // }, [updateAfterTotMillisecondsFromFirstRender, updateTrigger]);

  // Lo aggiorno al window.resize
  React.useEffect(() => {
    updateTrigger();
  }, [updateTrigger, windowSize]);

  // Eventualmetne lo aggiorno ogni tot millisedondi
  React.useEffect(() => {
    const interval = window.setInterval(() => {
      console.log('updateTrigger interval');
      updateTrigger();
    }, updateEveryTotMilliseconds);
    return () => {
      window.clearInterval(interval);
    };
  }, [updateEveryTotMilliseconds, updateTrigger]);

  // Crea l'oggetto equalHeightContext
  const equalHeightContext: EqualHeightContextInterface = React.useMemo(
    () => ({
      updateChecker: counter,
      updateTrigger,
    }),
    [counter, updateTrigger]
  );

  return {
    EqualHeightContextProvider,
    equalHeightContext,
  };
};

// Interfaccia componente contesto
export interface EqualHeightContextProps extends UseEqualHeightContextInitializerProps {
  children: React.ReactNode | ((equalHeightContext: EqualHeightContextInterface) => React.ReactNode);
}

// Componente Contesto
export const EqualHeightContext = ({ children, ...otherProps }: EqualHeightContextProps) => {
  const equalHeightContextInitializer = useEqualHeightContextInitializer(otherProps);
  return (
    <equalHeightContextInitializer.EqualHeightContextProvider value={equalHeightContextInitializer.equalHeightContext}>
      {typeof children === 'function' ? children(equalHeightContextInitializer.equalHeightContext) : children}
    </equalHeightContextInitializer.EqualHeightContextProvider>
  );
};
