import './MobileNavigationOffcanvas.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { Button } from './Button';
import { HeaderContentType } from './contentTypes';
import { t } from './i18n/i18n';
import { Icon } from './Icon';
import logo from './images/logo.svg';

interface MobileNavigationOffcanvasProps extends b2x.OffcanvasProps {}

const MobileNavigationOffcanvas = (props: MobileNavigationOffcanvasProps) => {
  const menu = b2x.useMenu('MENU_MOBILE', { populate: { content: true } });
  const content = b2x.useContent<HeaderContentType>('HEADER_CONTENT');

  return (
    <b2x.Offcanvas {...props}>
      <div className="mobile-navigation-offcanvas d-flex flex-column h-100 bg-black">
        <Header />
        <div className="flex-grow-1 _overflow-auto position-relative">
          <b2x.Drawers itemsClassName="px-4 py-1">
            <div className="d-flex flex-column h-100">
              <div className="flex-grow-1">
                <div className="search-container py-3 px-3">
                  <div className="bg-black rounded-pill overflow-hidden border border-gray-300">
                    <b2x.SimpleSearchForm submitIconName="search" />
                  </div>
                </div>
                <div className="menu-container">
                  {menu && <DrawerMenuItems item={menu} textUppercase />}
                  {content?.body.headerDesktop.ctaList?.map((item, index) => (
                    <div className="d-grid" key={item.contentSectionId}>
                      <b2x.CtaFromContent
                        {...item.cta}
                        ctaProps={{
                          button: {
                            className: 'text-white fw-medium btn-sm border-0 border-bottom py-3 lh-sm',
                            justifyContent: 'start',
                          },
                          link: { closeModal: true },
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <Footer content={content} />
            </div>
          </b2x.Drawers>
        </div>
      </div>
    </b2x.Offcanvas>
  );
};

const Header = () => {
  const { close } = b2x.useModalContext();

  return (
    <div>
      <div className="px-4 py-3 border-bottom border-gray-300 hstack gap-4 justify-content-between">
        <b2x.router.Link onClick={close} to="/">
          <b2x.Image className="logo" fluid src={logo} />
        </b2x.router.Link>
        <Button className="text-white" iconEnd={{ name: 'delete', size: 20 }} onClick={close} variant="blank" />
      </div>
    </div>
  );
};

interface FooterProps {
  content?: b2x.ContentApiDto<HeaderContentType>;
}

const Footer = ({ content }: FooterProps) => {
  const { info } = b2x.useAppContext();

  return (
    <div className="footer">
      <div className="px-4 py-3 border-bottom border-gray-400 small">
        <h6 className="text-uppercase text-white fw-bold small">{t('customerCare.contact')}</h6>
        {info?.customerCareEmail && (
          <div>
            <Icon className="me-2" name="email" size={16} /> {info.customerCareEmail}
          </div>
        )}
        {info?.customerCarePhoneNumber && (
          <div>
            <Icon className="me-2" name="phone" size={16} /> {info.customerCarePhoneNumber}
          </div>
        )}
      </div>
      <div className="drawer-locale-container py-2">
        {content?.body.topBar?.rightCta && (
          <div className="cta-container">
            <b2x.CtaFromContent
              {...content.body.topBar.rightCta}
              ctaProps={{
                button: {
                  className: 'text-white btn-sm',
                  iconStart: content.body.topBar.rightCta.icon
                    ? { name: content.body.topBar.rightCta.icon, size: 16 }
                    : undefined,
                },
              }}
            />
          </div>
        )}
        <DrawerLocaleItem />
      </div>
    </div>
  );
};

interface DrawerBackItemProps {
  label?: string;
}

const DrawerBackItem = ({ label = t('misc.back') }: DrawerBackItemProps) => {
  const { closeActiveDrawer } = b2x.useDrawersContext();

  return (
    <b2x.DrawerButtonItem onClick={closeActiveDrawer}>
      <b2x.DrawerItem
        center={{ center: label }}
        className="small gap-3 fw-normal text-white pb-2"
        height={35}
        justifyContent="start"
        start={{ center: <Icon name="arrow-sx" /> }}
      />
    </b2x.DrawerButtonItem>
  );
};

interface DrawerMenuItemsProps {
  depth?: number;
  item: b2x.MenuApiDto;
  textUppercase?: boolean;
}

const DrawerMenuItems = ({ depth = 1, item, textUppercase }: DrawerMenuItemsProps) => {
  const isLastBranch = React.useMemo(
    () => item.children.every((child) => child.children.length === 0),
    [item.children]
  );

  return (
    <>
      {item.children.map((child) => {
        const hasChildren = child.children.length > 0;
        //const childTextColor = child.tags?.includes('primary') ? 'text-white' : 'text-black';

        return hasChildren ? (
          <b2x.DrawerTriggerItem
            key={child.id}
            newDrawer={
              <Drawer backItemProps={{ label: child.label }}>
                <DrawerMenuItems depth={depth + 1} item={child} />
              </Drawer>
            }
          >
            <b2x.DrawerItem
              className={classnames(
                'fw-medium text-white small',
                { 'text-uppercase': textUppercase },
                `depth-${depth}`,
                { 'bg-primary': depth === 1 }
              )}
              end={{ center: <Icon className="text-white" name="arrow-dx" /> }}
              height={isLastBranch ? 40 : 50}
              justifyContent="between"
              start={{ center: child.label }}
            />
          </b2x.DrawerTriggerItem>
        ) : (
          <b2x.DrawerLinkItem key={child.id} to={child.link}>
            <b2x.DrawerItem
              className={classnames('small', { 'text-uppercase': textUppercase })}
              height={isLastBranch ? 40 : 50}
              justifyContent="start"
              start={{ center: child.label }}
            />
          </b2x.DrawerLinkItem>
        );
      })}
      {item.link && (
        <b2x.DrawerLinkItem to={item.link}>
          <b2x.DrawerItem
            className={classnames('text-white small', { 'fw-medium': !isLastBranch })}
            height={isLastBranch ? 40 : 50}
            justifyContent="start"
            start={{ center: t('misc.viewAll') }}
          />
        </b2x.DrawerLinkItem>
      )}
    </>
  );
};

interface DrawerProps {
  backItemProps?: DrawerBackItemProps;
  children: React.ReactNode;
}

const Drawer = ({ backItemProps, children }: DrawerProps) => {
  return (
    <div className="d-flex flex-column h-100 overflow-auto">
      <DrawerBackItem {...backItemProps} />
      <div className="bg-black flex-grow-1 overflow-auto">{children}</div>
    </div>
  );
};

const DrawerLocaleItem = () => {
  const { locale, shippingCountry } = b2x.useAppContext();

  return (
    <b2x.DrawerTriggerItem
      newDrawer={
        <Drawer>
          <div className="p-4">
            <b2x.LocaleForm singleColumn />
          </div>
        </Drawer>
      }
    >
      <b2x.DrawerItem
        className=""
        end={{
          center: <span className="extra-small text-decoration-underline text-white"> {t('account.edit')}</span>,
        }}
        height={40}
        justifyContent="between"
        start={{
          center: (
            <div className="hstack gap-2 small text-white">
              <Icon name="language" size={16} />
              <span style={{ textTransform: 'initial' }}>
                {shippingCountry?.name} | {locale?.languageDescription}
              </span>
            </div>
          ),
        }}
      />
    </b2x.DrawerTriggerItem>
  );
};

export const useMobileNavigationOffcanvas = (props?: MobileNavigationOffcanvasProps) =>
  b2x.useStaticModal(MobileNavigationOffcanvas, 'offcanvas', props);
