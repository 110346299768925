import { default as classNames, default as classnames } from 'classnames';

import { Button } from '../Button';
import { TextInput } from '../form/fields/Input';
import { Form } from '../form/Form';
import { FormGroup } from '../form/FormGroup';
import { GooglePlacesAutocomplete } from '../GooglePlacesAutocomplete';
import { Div } from '../HTMLElement';
import { Icon } from '../Icon';
import { SpeechRecognition } from '../SpeechRecognition';
import { GoogleMapInstance } from '../useGoogleMap';
import { uiClassName, wait } from '../util';
import { useStoreLocatorContext } from './StoreLocatorContext';

export interface SearchFormProps {
  className?: string;
  googleMap: GoogleMapInstance;
  inputClassName?: string;
  style?: React.CSSProperties;
}

export const SearchForm = ({ className, googleMap, inputClassName, style }: SearchFormProps) => {
  const {
    disableVoiceSearch,
    googlePlacesCountryRestrictions,
    iconClose,
    iconPin,
    iconSearch,
    iconSpeechRecognition,
    iconSpeechRecognitionActive,
    searchedPositionImage,
  } = useStoreLocatorContext();

  // const currentBreakpoint = useBreakpoint();
  // const disableSpeechRecognitionCondition = !untilBreakpoint('md', currentBreakpoint);

  return (
    <Div className={classnames('search-form', className)} style={style}>
      <Form
        initialValues={{ search: '' }}
        // eslint-disable-next-line react/jsx-no-bind
        onSubmit={() => {
          return new Promise(() => {
            //
          });
        }}
      >
        {({ formik }) => (
          <FormGroup className="search-form-group" names={['search']} noMarginBottom>
            <GooglePlacesAutocomplete
              componentRestrictions={
                googlePlacesCountryRestrictions ? { country: googlePlacesCountryRestrictions } : undefined
              }
              fields={['geometry', 'formatted_address', 'types']}
              // eslint-disable-next-line react/jsx-no-bind
              onPlaceChanged={(placeResult) => {
                // Devo distinguere da un risultato generico (roma) a uno più specifico (via caponia 8).
                // Nel secondo caso metto anche un marker dedicato.
                console.log(placeResult);
                if (placeResult.geometry?.location) {
                  googleMap.setSearchedPosition(
                    {
                      lat: placeResult.geometry.location.lat(),
                      lng: placeResult.geometry.location.lng(),
                    },
                    searchedPositionImage
                      ? {
                          image: {
                            id: 'searched-position',
                            src: searchedPositionImage,
                            width: 24,
                          },
                        }
                      : undefined
                  );
                  googleMap.setCenter(placeResult.geometry.location);
                  googleMap.setZoom(15);
                  formik.setFieldValue('search', placeResult.formatted_address);
                }
              }}
            >
              {([googlePlacesAutocompleteInputRef, autocomplete]) => (
                <SpeechRecognition
                  // eslint-disable-next-line react/jsx-no-bind
                  onResult={(result) => {
                    formik.setFieldValue('search', result);
                    wait(200).then(() => {
                      googlePlacesAutocompleteInputRef.current?.focus();
                    });
                  }}
                >
                  {(speechRecognition) => (
                    <>
                      <TextInput
                        className={classNames(inputClassName)}
                        innerRef={googlePlacesAutocompleteInputRef}
                        name="search"
                      />
                      <Icon className={uiClassName({ bs5: 'text-gray-500' })} name={iconPin} size={24} />
                      <Button
                        iconStart={{
                          name:
                            !disableVoiceSearch && speechRecognition.supported && speechRecognition.listening
                              ? iconSpeechRecognitionActive
                              : formik.values.search
                              ? iconClose
                              : !disableVoiceSearch && speechRecognition.supported
                              ? iconSpeechRecognition
                              : iconSearch,
                          size: 24,
                        }}
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick={() => {
                          if (formik.values.search) {
                            formik.setFieldValue('search', '');
                            googleMap.resetSearchedPosition();
                          } else {
                            if (!disableVoiceSearch && speechRecognition.supported) {
                              speechRecognition.toggle();
                            }
                          }
                        }}
                        variant="blank"
                      />
                    </>
                  )}
                </SpeechRecognition>
              )}
            </GooglePlacesAutocomplete>
          </FormGroup>
        )}
      </Form>
    </Div>
  );
};
