import classnames from 'classnames';
import React from 'react';

import { ColSize } from './Col';
import { Div } from './HTMLElement';
import {
  Gap,
  Responsive,
  ResponsiveAlignContent,
  ResponsiveAlignItems,
  ResponsiveJustifyContent,
  UiClassName,
} from './interfaces';
import { RowContext } from './RowContext';
import {
  buildAlignContentClassNames,
  buildAlignItemsClassNames,
  buildJustifyContentClassNames,
  renderUI,
} from './util';

export interface RowProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> {
  alignContent?: ResponsiveAlignContent;
  alignItems?: ResponsiveAlignItems;
  children?: React.ReactNode;
  cols?: Responsive<ColSize>;
  gap?: Responsive<Gap>;
  gapX?: Responsive<Gap>;
  gapY?: Responsive<Gap>;
  justifyContent?: ResponsiveJustifyContent;
  uiClassName?: UiClassName;
}

export const Row = ({
  alignContent,
  alignItems,
  children,
  className,
  cols,
  gap,
  gapX,
  gapY,
  justifyContent,
  uiClassName,
  ...otherProps
}: RowProps) => {
  const responsiveCols = typeof cols === 'object' ? cols : undefined;
  const responsiveGap = typeof gap === 'object' ? gap : undefined;
  const responsiveGapX = typeof gapX === 'object' ? gapX : undefined;
  const responsiveGapY = typeof gapY === 'object' ? gapY : undefined;

  return (
    <RowContext cols={cols}>
      {renderUI({
        bs5: (
          <Div
            {...otherProps}
            className={classnames(
              'row',
              { [`row-cols-${cols}`]: cols && typeof cols !== 'object' },
              { [`row-cols-${responsiveCols?.xs}`]: responsiveCols?.xs !== undefined },
              { [`row-cols-sm-${responsiveCols?.sm}`]: responsiveCols?.sm !== undefined },
              { [`row-cols-md-${responsiveCols?.md}`]: responsiveCols?.md !== undefined },
              { [`row-cols-lg-${responsiveCols?.lg}`]: responsiveCols?.lg !== undefined },
              { [`row-cols-xl-${responsiveCols?.xl}`]: responsiveCols?.xl !== undefined },
              { [`row-cols-xxl-${responsiveCols?.xxl}`]: responsiveCols?.xxl !== undefined },

              { [`g-${gap}`]: gap !== undefined && typeof gap !== 'object' },
              { [`g-${responsiveGap?.xs}`]: responsiveGap?.xs !== undefined },
              { [`g-sm-${responsiveGap?.sm}`]: responsiveGap?.sm !== undefined },
              { [`g-md-${responsiveGap?.md}`]: responsiveGap?.md !== undefined },
              { [`g-lg-${responsiveGap?.lg}`]: responsiveGap?.lg !== undefined },
              { [`g-xl-${responsiveGap?.xl}`]: responsiveGap?.xl !== undefined },
              { [`g-xxl-${responsiveGap?.xxl}`]: responsiveGap?.xxl !== undefined },

              { [`gx-${gapX}`]: gapX !== undefined && typeof gapX !== 'object' },
              { [`gx-${responsiveGapX?.xs}`]: responsiveGapX?.xs !== undefined },
              { [`gx-sm-${responsiveGapX?.sm}`]: responsiveGapX?.sm !== undefined },
              { [`gx-md-${responsiveGapX?.md}`]: responsiveGapX?.md !== undefined },
              { [`gx-lg-${responsiveGapX?.lg}`]: responsiveGapX?.lg !== undefined },
              { [`gx-xl-${responsiveGapX?.xl}`]: responsiveGapX?.xl !== undefined },
              { [`gx-xxl-${responsiveGapX?.xxl}`]: responsiveGapX?.xxl !== undefined },

              { [`gy-${gapY}`]: gapY !== undefined && typeof gapY !== 'object' },
              { [`gy-${responsiveGapY?.xs}`]: responsiveGapY?.xs !== undefined },
              { [`gy-sm-${responsiveGapY?.sm}`]: responsiveGapY?.sm !== undefined },
              { [`gy-md-${responsiveGapY?.md}`]: responsiveGapY?.md !== undefined },
              { [`gy-lg-${responsiveGapY?.lg}`]: responsiveGapY?.lg !== undefined },
              { [`gy-xl-${responsiveGapY?.xl}`]: responsiveGapY?.xl !== undefined },
              { [`gy-xxl-${responsiveGapY?.xxl}`]: responsiveGapY?.xxl !== undefined },

              alignItems !== undefined && buildAlignItemsClassNames(alignItems),
              justifyContent !== undefined && buildJustifyContentClassNames(justifyContent),
              alignContent !== undefined && buildAlignContentClassNames(alignContent),
              className
            )}
            uiClassName={uiClassName}
          >
            {children}
          </Div>
        ),
      })}
    </RowContext>
  );
};
