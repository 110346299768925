import { DirectoryApiDto } from '.';
import { ProductPopulate } from './products';
import { apiRequest, BaseOptions, getBaseOptions, Populate } from './utils/util';

export interface DirectoryPopulate {
  additionalContents?: boolean;
  associatedDirectories?: Populate<DirectoryPopulate>;
  // associatedProducts?: Populate<ProductPopulate>;
  children?: Populate<DirectoryPopulate>;
  content?: boolean;
  products?: Populate<ProductPopulate>;
}

const rootPath = '/v2/directories';

export interface GetDirectoryOptions extends BaseOptions {
  populate?: Populate<DirectoryPopulate>;
}

export const get = async <T, S>(id: string, options?: GetDirectoryOptions) =>
  apiRequest<DirectoryApiDto<T, S>, void, DirectoryPopulate>({
    ...getBaseOptions(options),
    populate: options?.populate,
    staleTime: Infinity,
    url: `${rootPath}/${id}`,
  });

export const getByCode = async <T, S>(code: string, options?: GetDirectoryOptions) =>
  apiRequest<DirectoryApiDto<T, S>, void, DirectoryPopulate>({
    ...getBaseOptions(options),
    populate: options?.populate,
    staleTime: Infinity,
    url: `${rootPath}/code/${code}`,
  });
