import { Image, ImageProps } from './Image';
import { ImageThron } from './ImageThron';

export type ImageSwitcherProps = ImageProps;

export const ImageSwitcher = ({ src, ...otherProps }: ImageSwitcherProps) => {
  const imageFromThron =
    (typeof src === 'string' && src.includes('.thron.')) || (typeof src === 'object' && src.xs.includes('.thron.'));

  return imageFromThron ? <ImageThron {...otherProps} quality={95} src={src} /> : <Image {...otherProps} src={src} />;
};
