/* eslint-disable react/forbid-elements */
import { Div } from './HTMLElement';
// import loading from './images/loading.svg';
import loadingBlack from './images/loading-black.svg';
import loadingWhite from './images/loading-white.svg';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface LoadingProps {
  color?: 'black' | 'white';
}

const Loading = ({ color = 'white' }: LoadingProps) => (
  <Div textAlign={'center'}>
    <img alt="loading..." height={100} src={color === 'white' ? loadingWhite : loadingBlack} width={100} />
  </Div>
);

export type LoadingVariants = '';

const LoadingController = (props: PropsWithCustomComponent<LoadingProps>) => (
  <VariantsController<LoadingProps, LoadingVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: Loading,
      name: 'Loading',
    }}
  />
);
export { LoadingController as Loading };
