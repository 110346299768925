import React from 'react';
import { EqualHeightElement as _EqualHeightElement } from 'react-equal-height';

import { Breakpoint } from './interfaces';
import { useRowContextStrict } from './RowContext';
import { useBreakpoint } from './useBreakpoint';
import { getRowIndex } from './util';

export interface EqualHeightElementProps {
  children?: React.ReactNode;
  colIndex?: number;
  disable?: boolean | Array<Breakpoint>;
  name: string;
  placeholder?: boolean;
  tag?: string;
}

export const EqualHeightElement = ({ colIndex, disable, name, ...otherProps }: EqualHeightElementProps) => {
  const breakpoint = useBreakpoint();

  const rowContext = useRowContextStrict();

  const _name = React.useMemo(
    () =>
      rowContext?.cols && colIndex !== undefined
        ? `${name}-row-${getRowIndex(breakpoint, colIndex, rowContext.cols)}`
        : name,
    [breakpoint, colIndex, name, rowContext?.cols]
  );

  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <_EqualHeightElement
      {...otherProps}
      disable={typeof disable === 'boolean' ? disable : disable?.includes(breakpoint) ? true : false}
      name={_name}
    />
  );
};
