// eslint-disable-next-line no-restricted-imports
import { api } from '@b2x/storefront-api-js-client/src';
import { CartPopulate, CartSkuPopulate, ShippingPackPopulate } from '@b2x/storefront-api-js-client/src/cart';
import {
  CartApiDto,
  CartSkuApiDto,
  PageApiDto,
  ProductApiDto,
  ShippingPackApiDto,
  SkuApiDto,
} from '@b2x/storefront-api-js-client/src/dto';
import { PagePopulate } from '@b2x/storefront-api-js-client/src/pages';
import { ProductPopulate, SkuPopulate } from '@b2x/storefront-api-js-client/src/products';
import { Populate } from '@b2x/storefront-api-js-client/src/utils/util';
import _ from 'lodash';

interface FillerOptions {
  silent?: boolean;
}

interface FillProductPopulate {
  alternativeImages?: boolean;
  // assembledComponents?: Populate<AssembledComponentPopulate>; // Da gestire
  // associatedProducts?: Populate<ProductPopulate>; // Da gestire
  // attributeGroups?: boolean; // Populate non gestita bene
  attributes?: boolean;
  brand?: boolean;
  breadcrumbs?: Populate<FillPagePopulate>; // Da gestire
  // bundleProducts?: Populate<ProductPopulate>; // Da gestire
  // content?: boolean; // Populate non gestita bene
  // offers?: boolean; // Populate non gestita bene
  // pages?: Populate<PagePopulate>; // Da gestire
  priceRange?: boolean;
  reviews?: boolean;
  skus?: Populate<FillSkuPopulate>;
  summaryReviews?: boolean;
  // variantProducts?: Populate<ProductPopulate>; // Da gestire
}

interface FillSkuPopulate {
  alternativeImages?: boolean;
  attributes?: boolean;
  // closestExpiringDate?: boolean; // Populate non gestita bene
  measurement?: boolean;
  price?: boolean;
  product?: Populate<FillProductPopulate>;
}

interface FillPagePopulate {
  // additionalContents?: Populate<ContentPopulate>;
  // associatedPages?: Populate<PagePopulate>;
  // breadcrumb?: Populate<PagePopulate>;
  // children?: Populate<PagePopulate>;
  content?: boolean;
  products?: Populate<FillProductPopulate>;
}

interface FillCartPopulate {
  alerts?: boolean;
  availableCoupons?: boolean;
  // billingAddress?: boolean; // Populate non gestita bene
  depositPayments?: boolean;
  offers?: boolean;
  packs?: Populate<FillShippingPackPopulate>;
  // paymentMethod?: boolean; // Populate non gestita bene
  // shippingAddress?: boolean; // Populate non gestita bene
  shippingProfile?: boolean;
}

interface FillShippingPackPopulate {
  alerts?: boolean;
  cartSkus?: Populate<FillCartSkuPopulate>;
}

interface FillCartSkuPopulate {
  alerts?: boolean;
  // giftCard?: boolean; // Populate non gestita bene
  offers?: boolean;
  sku?: Populate<FillSkuPopulate>;
}

const buildPopulateToFillProduct = (
  populate: Populate<FillProductPopulate>,
  product?: ProductApiDto
): Populate<ProductPopulate> => {
  if (typeof populate === 'object') {
    // vedo cosa devo prendere
    const alternativeImages = populate.alternativeImages && product?.alternativeImages === undefined;
    // const assembledComponents = populate.assembledComponents && product?.assembledComponents === undefined;
    // const associatedProducts = populate.associatedProducts && product?.associatedProducts === undefined;
    // const attributeGroups = populate.attributeGroups && product?.attributeGroups === undefined;
    const attributes = populate.attributes && product?.attributes === undefined;
    const brand = populate.brand && product?.brand === undefined;
    // const bundleProducts = populate.bundleProducts && product?.bundleProducts === undefined;
    // const content = populate.content && product?.content === undefined;
    // const offers = populate.offers && product?.offers === undefined;
    // const pages = populate.pages && product?.pages === undefined;
    const priceRange = populate.priceRange && product?.priceRange === undefined;
    const reviews = populate.reviews && product?.reviews === undefined;
    const summaryReviews = populate.summaryReviews && product?.summaryReviews === undefined;
    // const variantProducts = populate.variantProducts && product?.variantProducts === undefined;

    const testSku = product?.skus?.at(0);
    const populateToFillSku = populate.skus && buildPopulateToFillSku(populate.skus, testSku);
    const skus = populateToFillSku;

    const testBreadcrumb = product?.breadcrumbs?.at(0);
    const testPage = testBreadcrumb?.at(0);
    const populateToFillPage = populate.breadcrumbs && buildPopulateToFillPage(populate.breadcrumbs, testPage);
    const breadcrumbs = populateToFillPage;

    if (
      alternativeImages ||
      // assembledComponents ||
      // associatedProducts ||
      // attributeGroups ||
      attributes ||
      brand ||
      breadcrumbs ||
      // bundleProducts ||
      // content ||
      // offers ||
      // pages ||
      priceRange ||
      reviews ||
      skus ||
      summaryReviews
      // variantProducts
    ) {
      return {
        alternativeImages: alternativeImages,
        // assembledComponents: assembledComponents,
        // associatedProducts: associatedProducts,
        // attributeGroups: attributeGroups,
        attributes: attributes,
        brand: brand,
        breadcrumbs: breadcrumbs,
        // bundleProducts: bundleProducts,
        // content: content,
        // offers: offers,
        // pages: pages,
        priceRange: priceRange,
        reviews: reviews,
        skus: skus,
        summaryReviews: summaryReviews,
        // variantProducts: variantProducts,
      };
    } else {
      return false;
    }
  } else {
    return populate && product === undefined;
  }
};

const buildPopulateToFillSku = (populate: Populate<FillSkuPopulate>, sku?: SkuApiDto): Populate<SkuPopulate> => {
  if (typeof populate === 'object') {
    // vedo cosa devo prendere
    const alternativeImages = populate.alternativeImages && sku?.alternativeImages === undefined;
    const attributes = populate.attributes && sku?.attributes === undefined;
    // const closestExpiringDate = populate.closestExpiringDate && sku?.closestExpiringDate === undefined;
    const measurement = populate.measurement && sku?.measurement === undefined;
    const price = populate.price && sku?.price === undefined;
    // const product = populate.product && sku?.product === undefined;

    const testProduct = sku?.product;
    const populateToFillProduct = populate.product && buildPopulateToFillProduct(populate.product, testProduct);
    const product = populateToFillProduct;

    if (
      alternativeImages ||
      attributes ||
      // closestExpiringDate ||
      measurement ||
      price ||
      product
    ) {
      return {
        alternativeImages: alternativeImages,
        attributes: attributes,
        // closestExpiringDate: closestExpiringDate,
        measurement: measurement,
        price: price,
        product: product,
      };
    } else {
      return false;
    }
  } else {
    return populate && sku === undefined;
  }
};

const buildPopulateToFillPage = (populate: Populate<FillPagePopulate>, page?: PageApiDto): Populate<PagePopulate> => {
  if (typeof populate === 'object') {
    // vedo cosa devo prendere
    // const additionalContents = populate.additionalContents && page?.additionalContents === undefined;
    // const associatedPages = populate.associatedPages && page?.associatedPages === undefined;
    // const breadcrumb = populate.breadcrumb && page?.breadcrumb === undefined;
    // const children = populate.children && page?.children === undefined;
    const content = populate.content && page?.content === undefined;
    const products = populate.products && page?.products === undefined;

    if (
      // additionalContents ||
      // associatedPages ||
      // breadcrumb ||
      // children ||
      content ||
      products
    ) {
      return {
        // additionalContents: additionalContents,
        // associatedPages: associatedPages,
        // breadcrumb: breadcrumb,
        // children: children,
        content: content,
        products: products,
      };
    } else {
      return false;
    }
  } else {
    return populate && page === undefined;
  }
};

const buildPopulateToFillCart = (populate: Populate<FillCartPopulate>, cart?: CartApiDto): Populate<CartPopulate> => {
  if (typeof populate === 'object') {
    // vedo cosa devo prendere
    const alerts = populate.alerts && cart?.alerts === undefined;
    const availableCoupons = populate.availableCoupons && cart?.availableCoupons === undefined;
    // const billingAddress = populate.billingAddress && cart?.billingAddress === undefined;
    const depositPayments = populate.depositPayments && cart?.depositPayments === undefined;
    const offers = populate.offers && cart?.offers === undefined;
    // const paymentMethod = populate.paymentMethod && cart?.paymentMethod === undefined;
    // const shippingAddress = populate.shippingAddress && cart?.shippingAddress === undefined;
    const shippingProfile = populate.shippingProfile && cart?.shippingProfile === undefined;

    const testShippingPack = cart?.packs?.at(0);
    const populateToFillShippingPack =
      populate.packs && buildPopulateToFillShippingPack(populate.packs, testShippingPack);
    const packs = populateToFillShippingPack;

    if (
      alerts ||
      availableCoupons ||
      // billingAddress ||
      depositPayments ||
      offers ||
      packs ||
      // paymentMethod ||
      // shippingAddress ||
      shippingProfile
    ) {
      return {
        alerts: alerts,
        availableCoupons: availableCoupons,
        // billingAddress: billingAddress,
        depositPayments: depositPayments,
        offers: offers,
        packs: packs,
        // paymentMethod: paymentMethod,
        // shippingAddress: shippingAddress,
        shippingProfile: shippingProfile,
      };
    } else {
      return false;
    }
  } else {
    return populate && cart === undefined;
  }
};

const buildPopulateToFillShippingPack = (
  populate: Populate<FillShippingPackPopulate>,
  shippingPack?: ShippingPackApiDto
): Populate<ShippingPackPopulate> => {
  if (typeof populate === 'object') {
    // vedo cosa devo prendere
    const alerts = populate.alerts && shippingPack?.alerts === undefined;

    const testCartSku = shippingPack?.cartSkus?.at(0);
    const populateToFillCartSku = populate.cartSkus && buildPopulateToFillCartSku(populate.cartSkus, testCartSku);
    const cartSkus = populateToFillCartSku;

    if (alerts || cartSkus) {
      return {
        alerts: alerts,
        cartSkus: cartSkus,
      };
    } else {
      return false;
    }
  } else {
    return populate && shippingPack === undefined;
  }
};

const buildPopulateToFillCartSku = (
  populate: Populate<FillCartSkuPopulate>,
  cartSku?: CartSkuApiDto
): Populate<CartSkuPopulate> => {
  if (typeof populate === 'object') {
    // vedo cosa devo prendere
    const alerts = populate.alerts && cartSku?.alerts === undefined;
    // const giftCard = populate.giftCard && cartSku?.giftCard === undefined;
    const offers = populate.offers && cartSku?.offers === undefined;

    const testSku = cartSku?.sku;
    const populateToFillSku = populate.sku && buildPopulateToFillSku(populate.sku, testSku);
    const sku = populateToFillSku;

    if (
      alerts ||
      // giftCard ||
      offers ||
      sku
    ) {
      return {
        alerts: alerts,
        // giftCard: giftCard,
        offers: offers,
        sku: sku,
      };
    } else {
      return false;
    }
  } else {
    return populate && cartSku === undefined;
  }
};

const fillProducts = (products: Array<ProductApiDto>, populate: FillProductPopulate, options?: FillerOptions) =>
  new Promise<Array<ProductApiDto>>((resolve, reject) => {
    if (products.length > 0) {
      // Prendo un prodotto di prova per vedere se manca qualcosa.
      const testProduct = products[0];

      const populateToFillProduct = buildPopulateToFillProduct(populate, testProduct);

      if (populateToFillProduct) {
        !options?.silent &&
          console.log(
            'Filler',
            'fillProducts',
            'Manca qualcosa, per ottimizzare sarebbe meglio arrivare già con una populate completa.',
            populateToFillProduct
          );
        // Ho qualcosa da prendere, faccio chiamata alle api
        // console.log('fillProducts', 'chiamo le API');
        api.products
          .search(undefined, {
            limit: products.length,
            populate: {
              items: populateToFillProduct,
            },
            products: products.map((product) => product.id),
          })
          .then((response) => {
            // controllo di consistenza
            if (products.length !== response.data.items?.length) {
              throw new Error(
                `Filler, fillProducts, Ho tentato di fillare ${products.length} prodotti, ma ne ho ottenuti solo ${response.data.items?.length} dalle API.`
              );
            }
            // Unisco i dati di partenza con quelli appena ottenuti.
            const filledProducts: Array<ProductApiDto> = _.merge([], products, response.data.items);
            // console.log('fillProducts', resolve, filledProducts);
            resolve(filledProducts);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        // console.log('fillProducts', "non c'è stato bisogno di chiamare le API, avevo tutto", resolve, products);
        resolve(products);
      }
    } else {
      // console.log('fillProducts', resolve, products);
      resolve(products);
    }
  });

const fillProduct = (product: ProductApiDto, populate: FillProductPopulate, options?: FillerOptions) =>
  new Promise<ProductApiDto>((resolve, reject) => {
    const populateToFillProduct = buildPopulateToFillProduct(populate, product);

    if (populateToFillProduct) {
      !options?.silent &&
        console.log(
          'Filler',
          'fillProduct',
          'Manca qualcosa, per ottimizzare sarebbe meglio arrivare già con una populate completa.',
          populateToFillProduct
        );
      // Ho qualcosa da prendere, faccio chiamata alle api
      // console.log('fillProduct', 'chiamo le API');
      api.products
        .get(product.id, {
          populate: populateToFillProduct,
        })
        .then((response) => {
          // Unisco i dati di partenza con quelli appena ottenuti.
          const filledProduct: ProductApiDto = _.merge([], product, response.data);
          // console.log('fillProduct', resolve, filledProducts);
          resolve(filledProduct);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      // console.log('fillProduct', "non c'è stato bisogno di chiamare le API, avevo tutto", resolve, product);
      resolve(product);
    }
  });

const fillSku = (sku: SkuApiDto, populate: FillSkuPopulate, options?: FillerOptions) =>
  new Promise<SkuApiDto>((resolve, reject) => {
    // Prendo un prodotto di prova per vedere se manca qualcosa.

    const populateToFillSku = buildPopulateToFillSku(populate, sku);

    if (populateToFillSku) {
      !options?.silent &&
        console.log(
          'Filler',
          'fillSku',
          'Manca qualcosa, per ottimizzare sarebbe meglio arrivare già con una populate completa.',
          populateToFillSku
        );
      // Ho qualcosa da prendere, faccio chiamata alle api
      // console.log('fillSku', 'chiamo le API');
      api.products
        .getSku(sku.id, {
          populate: populateToFillSku,
        })
        .then((response) => {
          // Unisco i dati di partenza con quelli appena ottenuti.
          const filledSku: SkuApiDto = _.merge([], sku, response.data);
          // console.log('fillSku', resolve, filledSku);
          resolve(filledSku);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      // console.log('fillSku', "non c'è stato bisogno di chiamare le API, avevo tutto", resolve, sku);
      resolve(sku);
    }
  });

const fillSkus = (skus: Array<SkuApiDto>, populate: FillSkuPopulate, options?: FillerOptions) =>
  Promise.all(skus.map((sku) => fillSku(sku, populate, options)));

const fillCart = (cart: CartApiDto, populate: FillCartPopulate, options?: FillerOptions) =>
  new Promise<CartApiDto>((resolve, reject) => {
    const populateToFillCart = buildPopulateToFillCart(populate, cart);

    if (populateToFillCart) {
      !options?.silent &&
        console.log(
          'Filler',
          'fillCart',
          'Manca qualcosa, per ottimizzare sarebbe meglio arrivare già con una populate completa.',
          populateToFillCart
        );
      // Ho qualcosa da prendere, faccio chiamata alle api
      // console.log('fillCart', 'chiamo le API');
      api.cart
        .get({
          populate: populateToFillCart,
        })
        .then((response) => {
          // Unisco i dati di partenza con quelli appena ottenuti.
          const filledCart: CartApiDto = _.merge([], cart, response.data);
          // console.log('fillCart', resolve, filledCart);
          resolve(filledCart);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      // console.log('fillCart', "non c'è stato bisogno di chiamare le API, avevo tutto", resolve, products);
      resolve(cart);
    }
  });

export { fillCart, fillProduct, fillProducts, fillSku, fillSkus };
