import classnames from 'classnames';

import { AccountOffcanvasProps } from './AccountOffcanvas';
import { useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { appConfig } from './config';
import { AccountOffcanvasContentType } from './contentTypes';
import { CtaFromContent } from './CtaFromContent';
import { LoginForm } from './form/LoginForm';
import { Div } from './HTMLElement';
import { t } from './i18n/i18n';
import { Icon } from './Icon';
import { OffcanvasBody } from './Offcanvas';
import { Link } from './router/Link';
import { useContent } from './useContent';
import { formatHtml, renderUI } from './util';

export const AccountOffcanvasA = ({ close, title }: AccountOffcanvasProps) => {
  const content = useContent<AccountOffcanvasContentType>('ACCOUNT_OFFCANVAS_CONTENT');

  const { getFixedPagePath } = useAppStaticContext();

  return renderUI({
    bs5: (
      <>
        <div className="account-offcanvas d-flex flex-column h-100">
          <div className={classnames('offcanvas-header-wrapper p-3 pb-0 px-md-5')}>
            <div className={classnames('offcanvas-header p-0 pb-3 border-bottom')}>
              <h5 className="offcanvas-title">{title ?? content?.body.title}</h5>
              <Button
                aria-label="Close"
                data-bs-dismiss="offcanvas"
                iconEnd={appConfig.icons ? { name: appConfig.icons.close } : undefined}
                type="button"
                variant="blank"
              />
            </div>
          </div>
          <OffcanvasBody className="d-flex flex-column p-0">
            <div className="bg-white flex-grow-1 p-3 p-md-5 pt-4">
              <p className="offcanvas-body-content mb-3">{content?.body.login?.body}</p>
              <LoginForm onSuccess={close} />
            </div>
            <div className="mt-auto">
              <div className="bg-gray-100 p-3 p-md-5 offcanvas-body-registration-wrapper">
                <Div>
                  <h5 className="offcanvas-body-title">{formatHtml(content?.body.registration?.title)}</h5>
                  <p className="offcanvas-body-content mb-4">{formatHtml(content?.body.registration?.body)}</p>
                  {content?.body.registration?.iconsSection?.title && (
                    <Div className="small" fontWeight={700} marginY={3}>
                      {formatHtml(content.body.registration.iconsSection.title)}
                    </Div>
                  )}
                  {content?.body.registration?.iconsSection?.iconsList &&
                    content.body.registration.iconsSection.iconsList.length > 0 && (
                      <Div alignItems={'center'} display={'flex'} marginBottom={4} marginTop={3}>
                        {content.body.registration.iconsSection.iconsList.map((item) => (
                          <Div className="icon-box" key={item.contentSectionId} textAlign={'center'}>
                            <span className="icon">
                              {item.icon && item.icon && <Icon name={item.icon} size={30} />}
                            </span>
                            <Div className="small label" marginY={2}>
                              {item.label}
                            </Div>
                          </Div>
                        ))}
                      </Div>
                    )}

                  <div className="d-grid">
                    <Link className="btn btn-primary registration-cta" closeModal to={getFixedPagePath('register')}>
                      {t('account.register')}
                    </Link>
                  </div>
                </Div>
              </div>
              {content?.body.additionalRegistrationOptions && content.body.additionalRegistrationOptions.length > 0 && (
                <div className="py-2 px-3 px-md-5 offcanvas-additional-wrapper">
                  {content.body.additionalRegistrationOptions.map((additionalRegistrationOption) => (
                    <Div
                      className="offcanvas-additional"
                      key={additionalRegistrationOption.contentSectionId}
                      marginTop={2}
                    >
                      {additionalRegistrationOption.cta?.to && (
                        <div className="d-flex align-items-center pb-2">
                          <div>{formatHtml(additionalRegistrationOption.title)}</div>
                          <div className="ms-auto">
                            <CtaFromContent
                              {...additionalRegistrationOption.cta}
                              ctaProps={{
                                button: { className: 'text-decoration-underline text-promo fw-bold' },
                                link: { className: '', closeModal: true },
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </Div>
                  ))}
                </div>
              )}
              {/* {content?.body.additionalRegistrationOptions?.map((additionalRegistrationOption) => (
                <Div
                  className="px-3 px-md-5 offcanvas-additional"
                  key={additionalRegistrationOption.contentSectionId}
                  marginTop={2}
                >
                  {additionalRegistrationOption.cta?.to && (
                    <div className="d-flex justify-content-between align-items-center pb-2">
                      <div className="col-md-8">{formatHtml(additionalRegistrationOption.title)}</div>
                      <div className="col-md-4 ">
                        <CtaFromContent
                          {...additionalRegistrationOption.cta}
                          ctaProps={{
                            button: { className: 'text-decoration-underline text-promo fw-bold' },
                            link: { className: '', closeModal: true },
                          }}
                        />
                      </div>
                    </div>
                  )}
                </Div>
              ))} */}
            </div>
          </OffcanvasBody>
        </div>
      </>
    ),
  });
};
