import { ProductApiDto } from '@b2x/storefront-api-js-client/src/';
import { GetProductOptions } from '@b2x/storefront-api-js-client/src/products';
import React from 'react';

import { ApiRequestConfig } from './api/useApiRequest';
import { useProductsApi } from './api/useProductsApi';
import { appConfig } from './config';
import { useStable } from './util';

export type UseProductsByIdsResult = Array<ProductApiDto> | undefined;

export const useProductsByIds = (
  ids: Array<string | undefined>,
  options?: GetProductOptions,
  config?: ApiRequestConfig
): UseProductsByIdsResult => {
  const [products, setProducts] = React.useState<Array<ProductApiDto>>();

  const { searchProducts } = useProductsApi();

  const { populate = appConfig.api?.productTilePopulate, ...otherOptions } = options ?? {};

  const stableIds = useStable(ids);
  const otherStableOptions = useStable(otherOptions);
  const stablePopulate = useStable(populate);
  const stableConfig = useStable(config);

  React.useEffect(() => {
    searchProducts(
      undefined,
      {
        limit: stableIds.length,
        populate: { items: stablePopulate },
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        products: stableIds.filter((id) => id !== undefined).map((id) => id!),
        ...otherStableOptions,
      },
      stableConfig
    ).then((response) => {
      setProducts(response.data.items);
    });
  }, [stableIds, otherStableOptions, searchProducts, stableConfig, stablePopulate]);

  return products;
};
